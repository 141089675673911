import axios from "axios";

const auth_domain = "https://devapi.spotlet.in/auth";
// const auth_domain = "http://localhost:3000"

//signin
export const SignIn = (data) => {
  return axios.post(auth_domain + "/signin", data);
};
//get Employee details
export const getAdminDetails = () => {
  const auth = localStorage.getItem("admin");
  return axios.get(`${auth_domain}/admin`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
//get no of users
export const noOfUsers = () => {
  const auth = localStorage.getItem("admin");
  return axios.get(auth_domain + "/noofusers/",
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
};
//get user data by id
export const getUserData = (jwt_id) => {
  const auth = localStorage.getItem("admin");
  return axios.get(auth_domain + "/user/" + jwt_id,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
};

//**********LOCATIONS**********/

//get all incomplete locations`
export const getAllIncompleteLocations = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/incomplocations/?page=${data.page}&items_per_page=${data.items}`;
  if (data.searchTerm) {
    url = url + `&searchTerm=${data.searchTerm}`;
  }
  if (data.locationType) {
    url = url + `&locationType=${data.locationType}`;
  }
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
//get location by status`
export const getLocationByStatus = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/locationbystatus/?page=${data.page}&items_per_page=${data.items}`;
  if (data.status) {
    url = url + `&status=${data.status}`;
  }
  if (data.searchTerm) {
    url = url + `&searchTerm=${data.searchTerm}`;
  }
  if (data.subscriptionPlan) {
    url = url + `&subscriptionPlan=${data.subscriptionPlan}`;
  }
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
//get count ofl incomplete locations`
export const getCountIncompleteLocations = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + "/noofTemplocations";
  if (data.locationType) {
    url = url + `?locationType=${data.locationType}`;
  }
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
//get count off locations by status`
export const getCounLocationsByStatus = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/nooflocationsbystatus/?count=50`;
  if (data.status) {
    url = url + `&status=${data.status}`;
  }
  if (data.searchTerm) {
    url = url + `&searchTerm=${data.searchTerm}`;
  }
  if (data.subscriptionPlan) {
    url = url + `&subscriptionPlan=${data.subscriptionPlan}`;
  }
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
//get temp location
export const getTempLocation = (location_id) => {
  const auth = localStorage.getItem("admin");
  return axios.get(auth_domain + "/templocation/" + location_id,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
};
//update location
export const updateIncompleteLocation = (data) => {
  const auth = localStorage.getItem("admin");
  return axios.post(auth_domain + "/templocation/", data,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
};
//get approved location
export const getLocationById = (location_id) => {
  const auth = localStorage.getItem("admin");
  return axios.get(auth_domain + "/locations/" + location_id,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
};
export const getLocationTypes = async () => {
  const auth = localStorage.getItem("admin");
  const response = await axios.get(auth_domain + "/location_type_lookup", {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
  return response.data;
};
//update location
export const updateLocation = (data) => {
  const auth = localStorage.getItem("admin");
  return axios.post(auth_domain + "/updatelocation/", data,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
};
//upload location pics
export const uploadLocationPics = (formData) => {
  const auth = localStorage.getItem("admin");
  return axios.post(auth_domain + "/uploadlocpic", formData, {
    headers: {
      Authorization: `Bearer ${auth}`,
      "Content-Type": "multipart/form-data",
    },
  });
};
//upload gst docs
export const uploadGstDocs = (formData) => {
  const auth = localStorage.getItem("admin");
  return axios.post(auth_domain + "/uploadgst", formData, {
    headers: {
      Authorization: `Bearer ${auth}`,
      "Content-Type": "multipart/form-data",
    },
  });
};
//delete files from storage
export const deleteFiles = (form) => {
  const auth = localStorage.getItem("admin");
  return axios.delete(auth_domain + `/deletefile`, {
    headers: {
      Authorization: `Bearer ${auth}`
    },
    data: form
  });
}
//Update location status
export const updateLocationStatus = (locationId, status) => {
  const auth = localStorage.getItem("admin");
  axios.put(auth_domain + `/locations/${locationId}/status/${status}`, {},
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
};

//**********LOOKUPS**********/
export const getListTypes = () => {
  const auth = localStorage.getItem("admin");
  return axios.get(auth_domain + "/list_type_lookup",
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
}
export const getLocations = () => {
  const auth = localStorage.getItem("admin");
  return axios.get(auth_domain + "/location_type_lookup",
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
}
export const getAmenities = () => {
  const auth = localStorage.getItem("admin");
  return axios.get(auth_domain + "/amenities_type_lookup",
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
}
export const getDonts = () => {
  const auth = localStorage.getItem("admin");
  return axios.get(auth_domain + "/dontdos_type_lookup",
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
}
export const getDos = () => {
  const auth = localStorage.getItem("admin");
  return axios.get(auth_domain + "/dos_type_lookup",
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
}
export const getFeatures = () => {
  const auth = localStorage.getItem("admin");
  return axios.get(auth_domain + "/feature_type_lookup",
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
}
export const getRules = () => {
  const auth = localStorage.getItem("admin");
  return axios.get(auth_domain + "/rules_type_lookup",
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
}
export const viewLookup = (details) => {
  const auth = localStorage.getItem("admin");
  return axios.post(auth_domain + `/view_lookup`, { ...details }, {
    headers: {
      Authorization: `Bearer ${auth}`
    }
  });
}
export const deleteLookUp = (data) => {
  const auth = localStorage.getItem("admin");
  return axios.delete(auth_domain + `/delete_lookup`, {
    headers: {
      Authorization: `Bearer ${auth}`
    },
    data: data
  });
}
export const createLookUp = (details) => {
  const auth = localStorage.getItem("admin");
  return axios.post(auth_domain + "/create_lookup", { ...details },
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
}
export const updateLookup = (data) => {
  const auth = localStorage.getItem("admin");
  return axios.post(auth_domain + "/update_lookup", { ...data },
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
}

//**********BOOKINGS**********/
export const getBookings = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/booking/?page=${data.page}&items_per_page=${data.items}`;
  if (data.status) {
    url = url + `&status=${data.status}`;
  }
  if (data.paymentStatus) {
    url = url + `&paymentStatus=${data.paymentStatus}`;
  }
  if (data.searchTerm) {
    url = url + `&searchTerm=${data.searchTerm}`;
  }
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const noOfBookings = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/noofbookings/?`;
  if (data.status) {
    url = url + `status=${data.status}`;
  }
  if (data.paymentStatus) {
    url = url + `&paymentStatus=${data.paymentStatus}`;
  }
  if (data.searchTerm) {
    url = url + `&searchTerm=${data.searchTerm}`;
  }
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const locationBookings = (id) => {
  const auth = localStorage.getItem("admin");
  return axios.get(auth_domain + `/locationbookings/${id}`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

//**********CAREER/JOBS**********/
export const getJobs = (page, offSet) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/jobs/?page=${page}&items_per_page=${offSet}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const getJobRequests = () => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/requestedjobs`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const deleteJob = (id) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/jobs/${id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const createJob = (data) => {
  const auth = localStorage.getItem("admin");
  return axios.post(auth_domain + `/jobs`, data,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
};
export const getJobDetails = (id) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/jobDetails/${id}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const updateJob = (data, id) => {
  const auth = localStorage.getItem("admin");
  return axios.put(auth_domain + `/jobs`, { data, id },
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
};


//**********COUPONS**********/
export const createCoupon = (data) => {
  const auth = localStorage.getItem("admin");
  return axios.post(auth_domain + `/coupon`, data,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
};
export const getCoupons = (page, offSet) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/coupon/?page=${page}&items_per_page=${offSet}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const getCouponDetails = (id) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/coupon/${id}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const updateCoupon = (data) => {
  const auth = localStorage.getItem("admin");
  return axios.put(auth_domain + `/coupon`, data,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
};
export const deleteCoupon = (id) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/coupon/${id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};


//**********REVIEWS**********/
export const getAllLocationsReviews = (searchTerm, pageNo, noOfItems) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/review/?searchTerm=${searchTerm}&pageNo=${pageNo}&noOfItems=${noOfItems}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const getLocationReviews = (locationId, pageNo, noOfItems, searchTerm) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/review/${locationId}/?pageNo=${pageNo}&noOfItems=${noOfItems}&searchTerm=${searchTerm}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

//**********PERMISSIONS**********/
export const getPermissions = () => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/permission`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const createPermission = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/permission`;
  return axios.post(url, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const updatePermission = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/permission`;
  return axios.put(url, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const deletePermission = (id) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/permission/${id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

//**********ROLES**********/
export const getRoles = () => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/role`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const createRole = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/role`;
  return axios.post(url, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const updateRole = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/role`;
  return axios.put(url, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const deleteRole = (id) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/role/${id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

//**********STAFF**********/
export const getEmployees = (searchTerm) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/employee/?searchTerm=${searchTerm}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const createEmployee = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/employee`;
  return axios.post(url, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const updateEmployee = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/employee`;
  return axios.put(url, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const deleteEmployee = (id) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/employee/${id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const deactivateEmployee = (id) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/employee/${id}`;
  return axios.put(url, {}, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

//**********AUDIT_LOGS**********/
export const getLogs = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/logs/?searchTerm=${data?.searchTerm}&date=${data?.date}&level=${data?.level}&page=${data?.page}&noOfItems=${data?.noOfItems}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

//**********TESTIMONIALS**********/
export const createTestimonials = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/testimonials`;
  return axios.post(url, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const getTestimonials = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/testimonials/?page=${data.page}&noOfItems=${data.noOfItems}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const deleteTestimonial = (id) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/testimonials/${id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const getTestimonialsDetails = (id) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/testimonials/${id}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const updateTestimonials = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/testimonials`;
  return axios.put(url, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

//**********FEATURE_MANAGEMENT**********/
export const addToFeatureProperty = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/feature`;
  return axios.post(url, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const getFeatureProperties = (id) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/feature`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const updateFeatureProperties = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/feature`;
  return axios.put(url, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const deleteFeatureProperties = (locationId) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/feature/${locationId}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

//**********TOP_RATED**********/
export const addToTopRatedProperty = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/toprated`;
  return axios.post(url, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const getTopRatedProperties = (id) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/toprated`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const updateTopRatedProperties = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/toprated`;
  return axios.put(url, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const deleteTopRatedProperties = (locationId) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/toprated/${locationId}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

//**********EMAIL_TAMPLATE**********/
export const getAllEmailTemplates = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/email?page=${data.page}&noOfItems=${data.noOfItems}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}
export const getEmailTemplate = (id) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/email/${id}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}
export const addEmailTemplate = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/email`;
  return axios.post(url, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const updateEmailTemplate = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/email`;
  return axios.put(url, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const deleteEmailTemplate = (id) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/email/${id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

//**********NOTIFICATION_TAMPLATE**********/
export const getAllNotificationTemplates = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/notification?page=${data.page}&noOfItems=${data.noOfItems}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}
export const getNotificationTemplate = (id) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/notification/${id}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}
export const addNotificationTemplate = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/notification`;
  return axios.post(url, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const updateNotificationTemplate = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/notification`;
  return axios.put(url, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const deleteNotificationTemplate = (id) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/notification/${id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

// For Getting Booking details
export const getBookingDetails = (id) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/bookingdetails/${id}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

//Convenience fee
export const updateConvenienceFee = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/convenienceFee`;
  return axios.put(url, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const getConvenienceFee = () => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/convenienceFee`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}

// Adding Videos to location
export const uploadLocationVideos = async (formData) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/uploadlocvid`;
  const response = await axios.post(url, formData, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
  return response.data;
};

// Deleting Videos from storage
export const deleteVid = (formData) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/deletevid`;
  const response = axios.delete(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
    data: formData,
  });
  return response.data;
};

// Adding Cms page
export const addCmsPage = async (formData) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/cms`;
  return await axios.post(url, formData, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
//get single page details
export const getPageDetails = (id) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/cms/${id}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}
//get all pages details
export const getPages = (id) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/cms`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}
//update page
export const updatePage = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/cms`;
  return axios.put(url, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
// Deleting a page
export const deletePage = (id) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/cms/${id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

// For getting Enquiries data
export const getEnquiryData = (type) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/enquirydetails/${type}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}