import { useEffect, useState } from "react";
import {Link} from 'react-router-dom'
import { KTIcon } from "../../../../../_metronic/helpers";
import { getJobs,deleteJob} from "../../services/api";
// import Pagination from '@mui/material/Pagination';
// import Stack from '@mui/material/Stack';
import Moment from 'moment';
import { PageLoader } from "../../services/PageLoader";
import { useAuthStore } from "../../../../../app/modules/apps/store/auth";

const CareerList = () => {
    const { userProfile } = useAuthStore();
    const [data, setData] = useState<any>([]);
    const [searchData, setSearchData] = useState<any>([]);
    const [showLoader, setShowLoader] = useState(false)
    const getJobData = async () => {
        try{
            const res = await getJobs(1,50);
            setData(res.data.jobs)
            setSearchData(res.data.jobs)
            setShowLoader(false)
        }catch(error){
            console.log(error)
            setShowLoader(false)
        }
    }

    const handleDeleteJob = async (data) => {
        setShowLoader(true)
        const res = await deleteJob(data._id)
        getJobData();
    } 

    useEffect(() => {
        setShowLoader(true)
        getJobData();
    }, []);

    const hanldeSearch = (e) => {
        if (e.target.value === '') {
          setSearchData(data)
        }
        type Object = {};

        const newArrey:  Object[] = [];
        data.map((file, i) => {
          const isIncluded = file.jobTitle.toLowerCase().includes(e.target.value.toLowerCase())
          if (isIncluded) {
            return newArrey.push(file)
          }
        })
        setSearchData(newArrey)
      }

    // const handleChange = async (event, value) => {
    //     const resp = await getBookings({page : value,items : 50, status: bookingStatus, paymentStatus: paymentStatus, searchTerm: searchTerm});
    //     setData(resp?.data);
    // };

    // const filterData = async (bookingStatus:string, paymentStatus: string) => {
    //     console.log("filterData bookingStatus = " + bookingStatus);
    //     setBookingStatus(bookingStatus);
    //     setPaymentStatus(paymentStatus);
    // }

    return (
        <div className="card">
            <div className="card-header border-0 pt-6">
                <div className='card-title'>
                    {/* begin::Search */}
                    <div className='d-flex align-items-center position-relative my-1'>
                        <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                        <input
                            type='text'
                            data-kt-user-table-filter='search'
                            className='form-control form-control-solid w-250px ps-14'
                            placeholder='Search Job Title'
                            onChange={hanldeSearch}
                        />
                    </div>
                    {/* end::Search */}
                </div>
                {userProfile?.permissions?.Career_Management?.write && (
                    <div className="card-toolbar">
                        <Link to = {"/apps/career/job"}>
                            <button type='button' className='btn btn-primary'>
                                <KTIcon iconName='plus' className='fs-2' />
                                Add New Job
                            </button>
                        </Link>
                    </div>
                )}
            </div>
            
            <div>
                {/* begin::Body */}
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                        {/* begin::Table */}
                        <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                            {/* begin::Table head */}
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    {/* <th className='w-25px'>
                                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                            <input
                                                className='form-check-input'
                                                type='checkbox'
                                                value='1'
                                                data-kt-check='true'
                                                data-kt-check-target='.widget-13-check'
                                            />
                                        </div>
                                    </th> */}
                                    <th className='min-w-100px'>Job Title</th>
                                    <th className='min-w-100px'>Role</th>
                                    <th className='min-w-100px'>Openings</th>
                                    <th className='min-w-100px'>Applications</th>
                                    <th className='min-w-100px'>Posted date</th>
                                    <th className='min-w-100px text-end'>Actions</th>
                                </tr>
                            </thead>
                            {/* end::Table head */}
                            {/* begin::Table body */}
                            <tbody>
                                {searchData?.map((item, index) => {
                                    return (
                                        <tr>
                                            {/* <td>
                                                <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                                    <input className='form-check-input widget-13-check' type='checkbox' value='1' />
                                                </div>
                                            </td> */}
                                            <td>
                                                <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                                    {item.jobTitle}
                                                </a>
                                            </td>
                                            <td>
                                                <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                    {item?.role}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='badge badge-light-warning'>
                                                    {item?.openings}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                    {item?.noOfApplications}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='badge badge-light-warning'>
                                                    {Moment(item?.createdAt).format('DD-MM-YYYY')}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="text-end">
                                                    {/* <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                        title="View Locations">
                                                        <KTIcon iconName='magnifier' className='fs-3' />
                                                    </div> */}
                                                    <Link to ={`../job-view/${item._id}`} >
                                                        <div className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                                                            title="View">
                                                            View
                                                        </div>
                                                    </Link>
                                                    {userProfile?.permissions?.Career_Management?.write && (
                                                    <Link to ={`../job/${item._id}`} >
                                                        <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                            title="Update">
                                                            <KTIcon iconName='pencil' className='fs-3'/>
                                                        </div>
                                                    </Link>
                                                    )}
                                                     {userProfile?.permissions?.Career_Management?.delete && (
                                                    <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                        onClick={() =>handleDeleteJob(item)}
                                                        title="Delete">
                                                        <KTIcon iconName='trash' className='fs-3' />
                                                    </div>
                                                     )}
                                                    
                                                </div>
                                            </td>
                                        </tr>

                                    )
                                })}


                            </tbody>
                            {/* end::Table body */}
                        </table>
                        {/* end::Table */}
                    </div>
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>
            {/* <div style={{ display: 'flex', flexDirection: 'row-reverse', marginRight: "12px" }}>
                <Stack spacing={2}>
                    <Pagination count={dataCount} variant="outlined" shape="rounded" color="primary" onChange={handleChange} />
                </Stack>
            </div> */}
            {showLoader && <PageLoader />}
        </div>

    );
};

export default CareerList;
