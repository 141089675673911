import { useEffect, useState } from "react";
import {getPermissions, createPermission, updatePermission, deletePermission} from "../../services/api";
import {Modal,Button} from 'react-bootstrap'
import Moment from 'moment';
import { PageLoader } from "../../services/PageLoader";
import { useAuthStore } from "../../../../../app/modules/apps/store/auth";

const initialData = {
    created_by : 'Super Admin',
    name : '',
}
const PermissionList = () => {
    const { userProfile } = useAuthStore();
    const [data, setData] = useState<any>([]);
    const [formData,setFormData] = useState<any>(initialData);
    const [updateFormData,setUpdateFormData] = useState<any>({});
    const [show,setShow] = useState<any>(false);
    const [modelBody,setModelBody] = useState<string>('');
    const [showLoader, setShowLoader] = useState(false);

    const onChange = (e) => {
        const {name,value} = e.target
        setFormData({
            ...formData,
            [name] : value,
        })
    }
    const onChangeUpdate = (e) => {
        const {name,value} = e.target
        setUpdateFormData({
            ...updateFormData,
            [name] : value,
        })
    }
    const submitForm = async (e) => {
        e.preventDefault()
        setShowLoader(true)
        try{
            const resp : any = await createPermission(formData);
            const model = document.getElementById("permission_add_model") as HTMLFormElement
            model.click()
            // setModelBody(resp?.data);
            // setShow(true)
            alert(resp.data)
            setFormData(initialData)
            getData();
        }catch(error : any){
            console.log(error)
            setShowLoader(false)
        }
    }
    const submitUpdate = async(e) => {
        e.preventDefault();
        setShowLoader(true)
        try{
            updateFormData.modified_by = 'Super Admin'
            const resp : any = await updatePermission(updateFormData);
            const model = document.getElementById("permission_update_model") as HTMLFormElement
            model.click()
            // setModelBody(resp?.data);
            // setShow(true)
            alert(resp.data)
            getData();
            setUpdateFormData({})
        }catch(error : any){
            setShowLoader(false)
            console.log(error.response.data)
        }
    }
    const onClickDelete = async(id) => {
        try{
            const resp = await deletePermission(id);
            setModelBody(resp?.data);
            setShow(true)
            getData();
        }catch(error){
            console.log(error)
        }
    }
    const getData = async () => {
        try{
            const res = await getPermissions();
            setData(res.data)
            // setSearchData(res.data)
            setShowLoader(false)

        }catch(error){
            console.log(error)
            setShowLoader(false)
            alert(error)
        }
    }

    useEffect(() => {
        setShowLoader(true)
        getData();
    }, []);

    return (
        <div>
          <div id="kt_app_content" className="app-content flex-column-fluid">
            {/*<!--begin::Content container-->*/}
            <div id="kt_app_content_container" className="app-container container-xxl">
                {/*<!--begin::Card-->*/}
                <div className="card card-flush">
                    {/*<!--begin::Card header-->*/}
                    <div className="card-header mt-6">
                        {/*<!--begin::Card title-->*/}
                        <div className="card-title">
                            {/*<!--begin::Search-->*/}
                            {/* <div className="d-flex align-items-center position-relative my-1 me-5">
                                <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                </i>
                                <input type="text" data-kt-permissions-table-filter="search" className="form-control form-control-solid w-250px ps-13" placeholder="Search Permissions" />
                            </div> */}
                            {/*<!--end::Search-->*/}
                        </div>
                        {/*<!--end::Card title-->*/}
                        {/*<!--begin::Card toolbar-->*/}
                        {userProfile?.permissions?.Permission_Management?.write && (
                        <div className="card-toolbar">
                            {/*<!--begin::Button-->*/}
                            <button type="button" className="btn btn-light-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_add_permission">
                            <i className="ki-duotone ki-plus-square fs-3">
                                <span className="path1"></span>
                                <span className="path2"></span>
                                <span className="path3"></span>
                            </i>Add Permission</button>
                            {/*<!--end::Button-->*/}
                        </div>
                        )}
                        {/*<!--end::Card toolbar-->*/}
                    </div>
                    {/*<!--end::Card header-->*/}
                    {/*<!--begin::Card body-->*/}
                    <div className="card-body py-3">
                        {/*<!--begin::Table-->*/}
                        <div className='table-responsive'>
                        <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3" id="kt_permissions_table">
                            <thead>
                                <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                                    <th className="min-w-125px">Name</th>
                                    <th className="min-w-250px">Permission Id</th>
                                    <th className="min-w-250px">Created By</th>
                                    <th className="min-w-125px">Created Date</th>
                                    <th className="min-w-125px">Updated Date</th>
                                    <th className="text-end min-w-100px">Actions</th>
                                </tr>
                            </thead>
                            <tbody className="fw-semibold text-gray-600">
                            {data?.map((item) => {
                                    return (
                                        <tr>
                                            <td>
                                                <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6"'>
                                                    {item?.name}
                                                </div>
                                            </td>
                                            <td>
                                            <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6"'>
                                                    {item?.permission_id}
                                                </div>
                                            </td>
                                            <td>
                                                <a className="badge badge-light-primary fs-7 m-1">{item?.created_by}</a></td>
                                            <td>
                                            <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6"'>
                                                    {Moment(item?.createdAt).format('DD-MM-YYYY')}
                                                </div>
                                            </td>
                                            <td>
                                            <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6"'>
                                                    {Moment(item?.updatedAt).format('DD-MM-YYYY')}
                                                </div>
                                            </td>
                                            <td className="text-end">
                                            {userProfile?.permissions?.Permission_Management?.write && (
                                                <button title="update" className="btn btn-icon btn-active-light-primary w-30px h-30px me-3" data-bs-toggle="modal" data-bs-target="#kt_modal_update_permission" onClick={() => setUpdateFormData(item)}>
                                                    <i className="ki-duotone ki-setting-3 fs-3">
                                                        <span className="path1"></span>
                                                        <span className="path2"></span>
                                                        <span className="path3"></span>
                                                        <span className="path4"></span>
                                                        <span className="path5"></span>
                                                    </i>
                                                </button>
                                            )}
                                            {userProfile?.permissions?.Permission_Management?.delete && (
                                                <button title="delete" className="btn btn-icon btn-active-light-primary w-30px h-30px" data-kt-permissions-table-filter="delete_row" onClick={() => onClickDelete(item.permission_id)}>
                                                    <i className="ki-duotone ki-trash fs-3">
                                                        <span className="path1"></span>
                                                        <span className="path2"></span>
                                                        <span className="path3"></span>
                                                        <span className="path4"></span>
                                                        <span className="path5"></span>
                                                    </i>
                                                </button>
                                            )}
                                            </td>
                                        </tr>
                                        )
                                })}
                                {data.length === 0 && (
                                <tr>
                                    <td valign="top" className="dataTables_empty">No records found</td>
                                </tr>
                                )}
                            </tbody>
                        </table>
                        </div>
                        {/*<!--end::Table-->*/}
                    </div>
                    {/*<!--end::Card body-->*/}
                </div>
                {/*<!--end::Card-->*/}
                {/*<!--begin::Modals-->*/}
                {/*<!--begin::Modal - Add permissions-->*/}
                <div className="modal fade" id="kt_modal_add_permission"  aria-hidden="true">
                {/* tabindex="-1" */}
                    {/*<!--begin::Modal dialog-->*/}
                    <div className="modal-dialog modal-dialog-centered mw-650px">
                        {/*<!--begin::Modal content-->*/}
                        <div className="modal-content">
                            {/*<!--begin::Modal header-->*/}
                            <div className="modal-header">
                                {/*<!--begin::Modal title-->*/}
                                <h2 className="fw-bold">Add a Permission</h2>
                                {/*<!--end::Modal title-->*/}
                                {/*<!--begin::Close-->*/}
                                <div id='permission_add_model' className="btn btn-icon btn-sm btn-active-icon-primary" data-kt-permissions-modal-action="close" data-bs-toggle="modal" data-bs-target="#kt_modal_add_permission">
                                    <i className="ki-duotone ki-cross fs-1">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                    </i>
                                </div>
                                {/*<!--end::Close-->*/}
                            </div>
                            {/*<!--end::Modal header-->*/}
                            {/*<!--begin::Modal body-->*/}
                            <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
                                {/*<!--begin::Form-->*/}
                                <form id="kt_modal_add_permission_form" className="form" onSubmit={submitForm}>
                                    {/*<!--begin::Input group-->*/}
                                    <div className="fv-row mb-7">
                                        {/*<!--begin::Label-->*/}
                                        <label className="fs-6 fw-semibold form-label mb-2">
                                            <span className="required">Permission Name</span>
                                            <span className="ms-2" data-bs-toggle="popover" data-bs-trigger="hover" data-bs-html="true" data-bs-content="Permission names is required to be unique.">
                                                <i className="ki-duotone ki-information fs-7">
                                                    <span className="path1"></span>
                                                    <span className="path2"></span>
                                                    <span className="path3"></span>
                                                </i>
                                            </span>
                                        </label>
                                        {/*<!--end::Label-->*/}
                                        {/*<!--begin::Input-->*/}
                                        <input className="form-control form-control-solid" placeholder="Enter a permission name" name="name" onChange={onChange} value={formData?.name}/>
                                        {/*<!--end::Input-->*/}
                                    </div>
                                    {/*<!--end::Disclaimer-->*/}
                                    {/*<!--begin::Actions-->*/}
                                    <div className="text-center pt-15">
                                        {/* <button type="button" className="btn btn-light me-3">Discard</button> */}
                                        <button type="submit" className="btn btn-primary">
                                            <span className="indicator-label">Submit</span>
                                        </button>
                                    </div>
                                    {/*<!--end::Actions-->*/}
                                </form>
                                {/*<!--end::Form-->*/}
                            </div>
                            {/*<!--end::Modal body-->*/}
                        </div>
                        {/*<!--end::Modal content-->*/}
                    </div>
                    {/*<!--end::Modal dialog-->*/}
                </div>
                {/*<!--end::Modal - Add permissions-->*/}
                {/*<!--begin::Modal - Update permissions-->*/}
                <div className="modal fade" id="kt_modal_update_permission"  aria-hidden="true">
                {/* tabindex="-1" */}
                    {/*<!--begin::Modal dialog-->*/}
                    <div className="modal-dialog modal-dialog-centered mw-650px">
                        {/*<!--begin::Modal content-->*/}
                        <div className="modal-content">
                            {/*<!--begin::Modal header-->*/}
                            <div className="modal-header">
                                {/*<!--begin::Modal title-->*/}
                                <h2 className="fw-bold">Update Permission</h2>
                                {/*<!--end::Modal title-->*/}
                                {/*<!--begin::Close-->*/}
                                <div id='permission_update_model' className="btn btn-icon btn-sm btn-active-icon-primary" data-kt-permissions-modal-action="close" data-bs-toggle="modal" data-bs-target="#kt_modal_update_permission">
                                    <i className="ki-duotone ki-cross fs-1">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                    </i>
                                </div>
                                {/*<!--end::Close-->*/}
                            </div>
                            {/*<!--end::Modal header-->*/}
                            {/*<!--begin::Modal body-->*/}
                            <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
                                {/*<!--begin::Notice-->*/}
                                {/*<!--begin::Notice-->*/}
                                <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-9 p-6">
                                    {/*<!--begin::Icon-->*/}
                                    <i className="ki-duotone ki-information fs-2tx text-warning me-4">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        <span className="path3"></span>
                                    </i>
                                    {/*<!--end::Icon-->*/}
                                    {/*<!--begin::Wrapper-->*/}
                                    <div className="d-flex flex-stack flex-grow-1">
                                        {/*<!--begin::Content-->*/}
                                        <div className="fw-semibold">
                                            <div className="fs-6 text-gray-700">
                                            <strong className="me-1">Warning!</strong>By editing the permission name, you might break the system permissions functionality. Please ensure you're absolutely certain before proceeding.</div>
                                        </div>
                                        {/*<!--end::Content-->*/}
                                    </div>
                                    {/*<!--end::Wrapper-->*/}
                                </div>
                                {/*<!--end::Notice-->*/}
                                {/*<!--end::Notice-->*/}
                                {/*<!--begin::Form-->*/}
                                <form id="kt_modal_update_permission_form" className="form" onSubmit={submitUpdate}>
                                    {/*<!--begin::Input group-->*/}
                                    <div className="fv-row mb-7">
                                        {/*<!--begin::Label-->*/}
                                        <label className="fs-6 fw-semibold form-label mb-2">
                                            <span className="required">Permission Name</span>
                                            <span className="ms-2" data-bs-toggle="popover" data-bs-trigger="hover" data-bs-html="true" data-bs-content="Permission names is required to be unique.">
                                                <i className="ki-duotone ki-information fs-7">
                                                    <span className="path1"></span>
                                                    <span className="path2"></span>
                                                    <span className="path3"></span>
                                                </i>
                                            </span>
                                        </label>
                                        {/*<!--end::Label-->*/}
                                        {/*<!--begin::Input-->*/}
                                        <input className="form-control form-control-solid" placeholder="Enter a permission name" name="name" onChange={onChangeUpdate} value={updateFormData?.name}/>
                                        {/*<!--end::Input-->*/}
                                    </div>
                                    {/*<!--end::Input group-->*/}
                                    {/*<!--begin::Actions-->*/}
                                    <div className="text-center pt-15">
                                        {/* <button type="button" className="btn btn-light me-3" data-kt-permissions-modal-action="close" data-bs-toggle="modal" data-bs-target="#kt_modal_add_permission">Discard</button> */}
                                        <button type="submit" className="btn btn-primary">
                                            <span className="indicator-label">Submit</span>
                                        </button>
                                    </div>
                                    {/*<!--end::Actions-->*/}
                                </form>
                                {/*<!--end::Form-->*/}
                            </div>
                            {/*<!--end::Modal body-->*/}
                        </div>
                        {/*<!--end::Modal content-->*/}
                    </div>
                    {/*<!--end::Modal dialog-->*/}
                </div>
                {/*<!--end::Modal - Update permissions-->*/}
                {/*<!--end::Modals-->*/}
            </div>
            {/*<!--end::Content container-->*/}
        </div>
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
            <Modal.Title>Permissions</Modal.Title>
            </Modal.Header>
            <Modal.Body>{modelBody}</Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={() => setShow(false)}>
                Ok
            </Button>
            </Modal.Footer>
        </Modal>
        {showLoader && <PageLoader />}
        </div>
    );
};

export default PermissionList;
