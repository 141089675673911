import { DataGrid } from "@mui/x-data-grid";
import "./dataTable.scss";
import { useState } from "react";
import { UpdateRecordModal } from "./UpdateRecordModal";
import { KTIcon } from "../../../../../_metronic/helpers";
import { useAuthStore } from "../../../../../app/modules/apps/store/auth";

export const Table = ({ data, viewLocations, handleDelete, createLookUp }) => {
  const { userProfile } = useAuthStore();
  const [selectedData, setSelectedData] = useState({});
  const [open, setOpen] = useState(false)


  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 300,
      renderHeader: (params) => (
        <th className='min-w-150px'>Name</th>
      ),
      renderCell: (record) => (
        <div className='text-dark fw-bold text-hover-primary fs-6'>
          {record.row.name}
        </div>
      ),
    },
    {
      field: "value",
      headerName: "Value",
      width: 300,
      renderHeader: (params) => (
        <th className='min-w-150px'>Value</th>
      ),
      renderCell: (record) => (
        <div className='text-dark fw-bold text-hover-primary fs-6'>
          {record.row.value}
        </div>
      ),
    },
    {
      headerName: "Actions",
      width: 150,
      align: 'right',
      renderHeader: (params) => (
        <th className='min-w-150px'>Actions</th>
      ),
      renderCell: (record) => (
        // <Stack direction="row" spacing={3} className="text-end">
        <div className="text-end">
          <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() =>viewLocations(record?.row)}
               title="View Locations">
             <KTIcon iconName='magnifier' className='fs-3' />
          </div>
          {userProfile?.permissions?.Lookup_Management?.write && (
          <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() => {
                setSelectedData(record.row);
                setOpen(true)}}
              title="Edit">
            <KTIcon iconName='setting-3' className='fs-3' />
          </div>
          )}
          {userProfile?.permissions?.Lookup_Management?.delete && (
          <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() => handleDelete(record.row)}
              title="Delete">
            <KTIcon iconName='trash' className='fs-3' />
          </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="datatable">
      <DataGrid
        className="datagrid"
        rows={data}
        columns={columns}
        pageSize={9}
        rowsPerPageOptions={[9]}
        getRowId={(row) => row._id}
        sx={{
          "& .MuiDataGrid-row:hover": {
            backgroundColor: "inherit"
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "1px solid var(--bs-gray-100)"
          }
        }}
      />
      <UpdateRecordModal
        open={open}
        data={selectedData}
        handleClose={() => setOpen(false)}
        createLookUp={(details) => {
          setOpen(false)
        createLookUp(details)}}
      />
    </div>
  );
};
