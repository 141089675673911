import { useEffect, useState } from "react";
import {Link} from 'react-router-dom'
import { KTIcon } from "../../../../../_metronic/helpers";
import { getCoupons,updateCoupon} from "../../services/api";
// import Pagination from '@mui/material/Pagination';
// import Stack from '@mui/material/Stack';
import { PageLoader } from "../../services/PageLoader";
import { useAuthStore } from "../../../../../app/modules/apps/store/auth";

const CouponList = () => {
    const { userProfile } = useAuthStore();
    const [data, setData] = useState<any>([]);
    const [searchData, setSearchData] = useState<any>([]);
    const [showLoader, setShowLoader] = useState(false)
    const getCouponData = async () => {
        try{
            const res = await getCoupons(1,50);
            setData(res.data.coupons)
            setSearchData(res.data.coupons)
            setShowLoader(false)
        }catch(error){
            console.log(error)
            setShowLoader(false)
        }
    }

    const handleUpdatreStatus = async (data) => {
        const updatedData = {
            ...data,
            status : data.status === 'Active' ? 'Deactivated' : "Active",
        }
        setShowLoader(true)
        const res = await updateCoupon(updatedData)
        getCouponData();
    } 

    useEffect(() => {
        setShowLoader(true)
        getCouponData();
    }, []);

    const hanldeSearch = (e) => {
        if (e.target.value === '') {
          setSearchData(data)
        }
        type Object = {};

        const newArrey:  Object[] = [];
        data.map((file, i) => {
          let isIncluded = file.couponName.toLowerCase().includes(e.target.value.toLowerCase())
          if (isIncluded) {
            return newArrey.push(file)
          }else{
            isIncluded = file.couponId.toLowerCase().includes(e.target.value.toLowerCase())
            if(isIncluded){
                return newArrey.push(file)
            }
          }
        })
        setSearchData(newArrey)
      }

    // const handleChange = async (event, value) => {
    //     const resp = await getBookings({page : value,items : 50, status: bookingStatus, paymentStatus: paymentStatus, searchTerm: searchTerm});
    //     setData(resp?.data);
    // };

    // const filterData = async (bookingStatus:string, paymentStatus: string) => {
    //     console.log("filterData bookingStatus = " + bookingStatus);
    //     setBookingStatus(bookingStatus);
    //     setPaymentStatus(paymentStatus);
    // }

    return (
        <div className="card">
            <div className="card-header border-0 pt-6">
                <div className='card-title'>
                    {/* begin::Search */}
                    <div className='d-flex align-items-center position-relative my-1'>
                        <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                        <input
                            type='text'
                            data-kt-user-table-filter='search'
                            className='form-control form-control-solid w-250px ps-14'
                            placeholder='Search Coupon'
                            onChange={hanldeSearch}
                        />
                    </div>
                    {/* end::Search */}
                </div>
                {userProfile?.permissions?.Coupon_Management?.write && (
                <div className="card-toolbar">
                    <Link to = {"/apps/coupon/new_coupon"}>
                        <button type='button' className='btn btn-primary'>
                            <KTIcon iconName='plus' className='fs-2' />
                            Add New Coupon
                        </button>
                </Link>
        </div>
                )}
            </div>
            
            <div>
                {/* begin::Body */}
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                        {/* begin::Table */}
                        <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                            {/* begin::Table head */}
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    {/* <th className='w-25px'>
                                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                            <input
                                                className='form-check-input'
                                                type='checkbox'
                                                value='1'
                                                data-kt-check='true'
                                                data-kt-check-target='.widget-13-check'
                                            />
                                        </div>
                                    </th> */}
                                    <th className='min-w-100px'>Coupon Name</th>
                                    <th className='min-w-100px'>Coupon Id</th>
                                    <th className='min-w-100px'>Coupon Type</th>
                                    <th className='min-w-100px'>Discount Type</th>
                                    <th className='min-w-100px'>Amount / Discount %</th>
                                    <th className='min-w-100px'>Activation Date</th>
                                    <th className='min-w-100px'>Expiry Date</th>
                                    <th className='min-w-100px'>Status</th>
                                    <th className='min-w-100px'>Actions</th>
                                </tr>
                            </thead>
                            {/* end::Table head */}
                            {/* begin::Table body */}
                            <tbody>
                                {searchData?.map((item, index) => {
                                    return (
                                        <tr>
                                            {/* <td>
                                                <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                                    <input className='form-check-input widget-13-check' type='checkbox' value='1' />
                                                </div>
                                            </td> */}
                                            <td>
                                                <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6"'>
                                                    {item?.couponName}
                                                </div>
                                            </td>
                                            <td>
                                                <a href='#' className='text-dark fw-bold text-hover-primary fs-6"'>
                                                    {item.couponId}
                                                </a>
                                            </td>
                                            <td>
                                                <div className='badge badge-light-warning'>
                                                    {item?.couponType}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6"'>
                                                    {item?.discountType}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6"'>
                                                    {item?.couponValue}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6"'>
                                                    {item?.activationDate}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6"'>
                                                    {item?.expiryDate}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='badge badge-light-warning'>
                                                    {item?.status}
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    {/* <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                        title="View Locations">
                                                        <KTIcon iconName='magnifier' className='fs-3' />
                                                    </div> */}
                                                    {/* <Link to ={`../job-view/${item._id}`} >
                                                        <div className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                                                            title="View">
                                                            View
                                                        </div>
                                                    </Link> */}
                                                    {userProfile?.permissions?.Coupon_Management?.write && (
                                                    <Link to ={`../update_coupon/${item._id}`} >
                                                        <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                            title="Update">
                                                            <KTIcon iconName='pencil' className='fs-3'/>
                                                        </div>
                                                    </Link>
                                                    )}
                                                    {userProfile?.permissions?.Coupon_Management?.delete && (
                                                    <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                        onClick={() =>handleUpdatreStatus(item)}
                                                        title={item?.status === 'Active' ?  'Deactivate' : 'Activate'}>
                                                        <KTIcon iconName={item?.status === 'Active' ?  'cross-circle' : 'verify'} className='fs-3' />
                                                    </div>
                                                    )}
                                                    
                                                </div>
                                            </td>
                                        </tr>

                                    )
                                })}


                            </tbody>
                            {/* end::Table body */}
                        </table>
                        {/* end::Table */}
                    </div>
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>
            {/* <div style={{ display: 'flex', flexDirection: 'row-reverse', marginRight: "12px" }}>
                <Stack spacing={2}>
                    <Pagination count={dataCount} variant="outlined" shape="rounded" color="primary" onChange={handleChange} />
                </Stack>
            </div> */}
            {showLoader && <PageLoader />}
        </div>

    );
};

export default CouponList;
