import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { getUserData } from "../../services/api";
import { UserAccountHeader } from "./UserAccountHeader";

const UserDetails = () => {
	const params = useParams();
	const userId = params.userId;
  
  const [personalInfo, setPersonalInfo] = useState<any>([]);
	const [bookingsData, setBookingsData] = useState([]);
	const [listingsData, setListingsData] = useState([]);
	
	useEffect(() => {
		const fetchData = async () => {
			try{
				const res = await getUserData(userId);
				setPersonalInfo(res?.data?.personalInfo);
				setBookingsData(res?.data?.portfolio);
				setListingsData(res?.data?.listedLocations);
			}catch(error){
				console.log(error)
			}
		};
		fetchData();
	}, []);

	// const getDate = (timestamp) => {
	// 	const date = new Date(timestamp);
	// 	const yyyy = date.getFullYear();
	// 	let mm = date.getMonth() + 1; // Months start at 0!
	// 	let dd = date.getDate();
	// 	const finalDate = dd + "-" + mm + "-" + yyyy;
	// 	return finalDate;
	// };

	// const gridActionButton = (props) => (
	// 	<Link
	// 		to={props}
	// 		style={{
	// 			textDecoration: "none",
	// 		}}
	// 	>
	// 		<Button
	// 			variant="outlined"
	// 			sx={{
	// 				padding: "6px 10px",
	// 				border: "1px solid #EA4235",
	// 				color: "black",
	// 				fontWeight: "600",
	// 				borderRadius: "4px",
	// 				marginTop: "10px",
	// 			}}
	// 		>
	// 			Details
	// 		</Button>
	// 	</Link>
	// );

	// const gridLocationId = (props) => (
	// 	<div
	// 		style={{
	// 			display: "flex",
	// 			justifyContent: "center",
	// 			alignItems: "center",
	// 			gap: "5px",
	// 		}}
	// 	>
	// 		<GoPrimitiveDot color="#EA4235" />
	// 		{props.row.LocationId}
	// 	</div>
	// );

	// const gridBookingStatus = (props) => {
	// 	let color;
	// 	if (props === "Under Review") color = "#E8B500";
	// 	else if (props === "Approved") color = "#0079D7";
	// 	else if (props === "Cancelled") color = "#E20000";
	// 	else if (props === "Booked") color = "#19AF00";

	// 	return (
	// 		<div
	// 			style={{
	// 				textAlign: "center",
	// 				color: color,
	// 			}}
	// 		>
	// 			{props}
	// 		</div>
	// 	);
	// };

	const [value, setValue] = useState({});
	console.log(value)

	return (
		<>
			<UserAccountHeader />
			{/* <UserOverview/>  */}
		</>
	);
};

export default UserDetails;
