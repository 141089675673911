import { useEffect, useState } from "react";
import {Modal,Button} from 'react-bootstrap'
import { PageLoader } from "../services/PageLoader";
import { updateConvenienceFee, getConvenienceFee } from "../services/api";
import { useAuthStore } from "../../../../app/modules/apps/store/auth";

const Payment = () => {
    const { userProfile } = useAuthStore();
    const [show,setShow] = useState<any>(false);
    const [modelBody,setModelBody] = useState<string>('');
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [formData, setFormData] = useState<any>({
        active : false,
        percentage : '',
        eligible : '',
    });

    const getData = async () => {
        try{
            const resp = await getConvenienceFee()
            setFormData(resp?.data)
            setShowLoader(false)
        }catch(error){
            console.log(error)
            setShowLoader(false)
        }
    }

    const submitForm = async (e) => {
        e.preventDefault()
        try{
            setShowLoader(true)
            const resp = await updateConvenienceFee(formData)
            setFormData(resp?.data)
            getData()
            setModelBody(resp?.data)
            setShow(true)
        }catch(error){
            console.log(error)
            setShowLoader(false)
        }
    }

    useEffect(()=> {
        setShowLoader(true)
        getData();
    },[]);

    return (
        <form className="card p-2" onSubmit={submitForm}>
            <div className="row mb-2">
                <label className="col-lg-4 col-form-label fw-semibold fs-6">
                    <span>Convenience Fee</span>
                        <span className="ms-1" data-bs-toggle="tooltip" title="Convenience fee on bookings">
                            <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                <span className="path1"></span>
                                <span className="path2"></span>
                                <span className="path3"></span>
                            </i>
                        </span>
                </label>
                <div className="col-lg-8 d-flex align-items-center">
                    <div className="form-check form-check-solid form-switch form-check-custom fv-row">
                        <input onChange={(e) => setFormData({...formData,[e.target.name] : e.target.checked})} name="active" checked={formData?.active} className="form-check-input w-45px h-30px" type="checkbox" id="active" />
                        <label className="form-check-label" htmlFor="active"></label>
                    </div>
                </div>
            </div>
            <div className="row mb-2">
                <label className="col-lg-4 col-form-label fw-semibold fs-6">
                    <span>Percentage</span>
                        <span className="ms-1" data-bs-toggle="tooltip" title="Percentage accept only 0 to 100">
                            <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                <span className="path1"></span>
                                <span className="path2"></span>
                                <span className="path3"></span>
                            </i>
                        </span>
                </label>
                <div className="col-lg-8 d-flex align-items-center">
                    <div className="form-check form-check-solid form-switch form-check-custom fv-row">
                        <input
                        onChange={(e)=> {
                            const regex = /^(?!-?[eE]$)([0-9]|[1-9][0-9]|100)$/;
                            if (e.target.value === "" || regex.test(e.target.value)) {
                                setFormData({
                                ...formData,
                                [e.target.name] : e.target.value,
                              })
                            }
                        }}
                        disabled = {!formData.active}
                        required
                        name="percentage" value={formData?.percentage} type="number" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="value in %" id="percentage" />
                        <label className="form-check-label" htmlFor="percentage"></label>
                    </div>
                </div>
            </div>
            <div className="row mb-2">
                <label className="col-lg-4 col-form-label fw-semibold fs-6">
                    <span>Maximum amount upto</span>
                        <span className="ms-1" data-bs-toggle="tooltip" title="Convenience fee is elible on bookings">
                            <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                <span className="path1"></span>
                                <span className="path2"></span>
                                <span className="path3"></span>
                            </i>
                        </span>
                </label>
                <div className="col-lg-8 d-flex align-items-center">
                    <div className="form-check form-check-solid form-switch form-check-custom fv-row">
                        <input disabled = {!formData.active} required onChange={(e) => setFormData({...formData,[e.target.name] : e.target.value})} name='eligible' value={formData?.eligible} type="number" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="price" id="eligible" />
                        <label className="form-check-label" htmlFor="eligible"></label>
                    </div>
                </div>
            </div>
            {userProfile?.permissions?.Payment_Management?.write && (<div className="card-footer d-flex justify-content-end py-6 px-9">
                {/* <button type="reset" className="btn btn-light btn-active-light-primary me-2">Discard</button> */}
                <button type="submit" className="btn btn-primary" id="kt_account_profile_details_submit">Save Changes</button>
            </div>)}
            <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
            <Modal.Title>Payments</Modal.Title>
            </Modal.Header>
            <Modal.Body>{modelBody}</Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={() => setShow(false)}>
                Ok
            </Button>
            </Modal.Footer>
        </Modal>
        {showLoader && <PageLoader />}
        </form>

    );
};

export default Payment;
