/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import { useAuthStore } from "../../../../../app/modules/apps/store/auth";

const SidebarMenuMain = () => {
  const intl = useIntl()
  const { userProfile, setUserProfile } = useAuthStore();

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      {/* <SidebarMenuItem to='/builder' icon='switch' title='Layout Builder' fontIcon='bi-layers' />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='element-plus'
      >
        <SidebarMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <SidebarMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <SidebarMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <SidebarMenuItem
            to='/crafted/pages/profile/campaigns'
            title='Campaigns'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/documents'
            title='Documents'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <SidebarMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <SidebarMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='profile-circle'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub to='/error' title='Errors' fontIcon='bi-sticky' icon='cross-circle'>
        <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='element-7'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>List Management</span>
        </div>
      </div>
      {/* <SidebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='message-text-2'
      >
        <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      {userProfile?.permissions?.Lookup_Management?.read && (
        <SidebarMenuItem
          to='/apps/lookup/list'
          icon='shield-search'
          title='Lookup Management'
          fontIcon='bi-layers'
        />
      )}
      <SidebarMenuItemWithSub
        to='/apps/locations'
        title='Location Management'
        fontIcon='bi-chat-left'
        icon='map'
      >
        {userProfile?.permissions?.Incomplete_Locations?.read && (<SidebarMenuItem to='/apps/location-management/incomplete' title='Incomplete Locations' hasBullet={true} />)}
        {userProfile?.permissions?.Location_Requests?.read && (<SidebarMenuItem to='/apps/location-management/requests' title='Location Requests' hasBullet={true} />)}
        {userProfile?.permissions?.Location_for_Approval?.read && (<SidebarMenuItem to='/apps/location-management/reviewed' title='Location for approval' hasBullet={true} />)}
        {userProfile?.permissions?.Approved_Locations?.read && (<SidebarMenuItem to='/apps/location-management/approved' title='Approved Locations' hasBullet={true} />)}
        {userProfile?.permissions?.Rejected_Locations?.read && (<SidebarMenuItem to='/apps/location-management/rejected' title='Rejected Locations' hasBullet={true} />)}
        {userProfile?.permissions?.Deactivated_Locations?.read && (<SidebarMenuItem to='/apps/location-management/deactivated' title='Deactivated Locations' hasBullet={true} /> )}
        {userProfile?.permissions?.Featured_Locations?.read && (<SidebarMenuItem to='/apps/location-management/featured' title='Featured Locations' hasBullet={true} /> )}
        {userProfile?.permissions?.Top_Rated_Locations?.read && (<SidebarMenuItem to='/apps/location-management/toprated' title='Top Rated Locations' hasBullet={true} /> )}

      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/apps/review'
        title='Review Management'
        fontIcon='bi-chat-left'
        icon='abstract-36'
      >
        {userProfile?.permissions?.Locations_Reviews?.read && (<SidebarMenuItem to='/apps/review' title='Locations Reivews' hasBullet={true} />)}
        {/* <SidebarMenuItem to='/apps/users-reivews' title='Users Reviews' hasBullet={true} /> */}
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/apps/role-management'
        title='Role Management'
        fontIcon='bi-chat-left'
        icon='abstract-20'
      >
        {userProfile?.permissions?.Role_Management?.read && (<SidebarMenuItem to='/apps/role-management' title='Roles list' hasBullet={true} />)}
        {userProfile?.permissions?.Permission_Management?.read && (<SidebarMenuItem to='/apps/role-management/permissions' title='Permissions' hasBullet={true} />)}
      </SidebarMenuItemWithSub>
      {userProfile?.permissions?.Staff_Management?.read && (
      <SidebarMenuItem
        to='/apps/staff-management/staff'
        icon='address-book'
        title='Staff management'
        fontIcon='bi-layers'
      />)}
      {userProfile?.permissions?.User_Management?.read && (
      <SidebarMenuItem
        to='/apps/users/list'
        icon='address-book'
        title='User management'
        fontIcon='bi-layers'
      />)}
      {userProfile?.permissions?.Booking_Management?.read && (
      <SidebarMenuItem
        to='/apps/bookings/list'
        icon='calendar'
        title='Bookings'
        fontIcon='bi-layers'
      />)}
      {userProfile?.permissions?.Subscription_Management?.read && (
      <SidebarMenuItem
        to='/apps/subscriptions/list'
        icon='dollar'
        title='Subscriptions'
        fontIcon='bi-layers'
      />)}
      {userProfile?.permissions?.Career_Management?.read && (
      <SidebarMenuItem
        to='/apps/career'
        icon='burger-menu-2'
        title='Careers'
        fontIcon='bi-layers'
      />)}
      {userProfile?.permissions?.Coupon_Management?.read && (
      <SidebarMenuItem
        to='/apps/coupon'
        icon='element-plus'
        title='Coupons'
        fontIcon='bi-archive'
      />)}
      {userProfile?.permissions?.Job_Management?.read && (
      <SidebarMenuItem
        to='/apps/jobrequests'
        icon='binance-usd'
        title='Job Requests'
        fontIcon='bi-layers'
      />)}
      {userProfile?.permissions?.Audit_Logs?.read && (<SidebarMenuItem
        to='/apps/logs'
        icon='abstract-27'
        title='Audit Logs'
        fontIcon='bi-layers'
      />)}
      {userProfile?.permissions?.Testimonial_Management?.read && (<SidebarMenuItem
        to='/apps/testimonials'
        icon='abstract-33'
        title='Testimonial Management'
        fontIcon='bi-layers'
      />)}
      {userProfile?.permissions?.Email_Management?.read && (<SidebarMenuItem
        to='/apps/email'
        icon='abstract-25'
        title='Email Management'
        fontIcon='bi-layers'
      />)}
      {userProfile?.permissions?.Notification_Management?.read && (<SidebarMenuItem
        to='/apps/notification'
        icon='abstract-40'
        title='Notification Management'
        fontIcon='bi-layers'
      />)}
      {userProfile?.permissions?.Payment_Management?.read && (<SidebarMenuItem
        to='/apps/payments'
        icon='abstract-41'
        title='Payment Management'
        fontIcon='bi-layers'
      />)}
      {userProfile?.permissions?.Cms_management?.read && (<SidebarMenuItem
        to='/apps/cms'
        icon='abstract-49'
        title='CMS Management'
        fontIcon='bi-layers'
      />)}
      {userProfile?.permissions?.Cms_management?.read && (<SidebarMenuItem
        to='/apps/enquiries'
        icon='abstract-9'
        title='Enquiry Management'
        fontIcon='bi-layers'
      />)}
      {/* <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTIcon iconName='code' className='fs-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}

export {SidebarMenuMain}
