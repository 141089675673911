import { useState ,useRef } from "react";
import { useNavigate } from "react-router-dom";
import JoditEditor from 'jodit-react';
import {Modal,Button} from 'react-bootstrap'
import { PageLoader } from "../services/PageLoader";
import {addEmailTemplate} from "../services/api";

const AddEmailTamplate = () => {
    const editor = useRef(null);
    const navigate = useNavigate();
    const [show,setShow] = useState<any>(false);
    const [modelBody,setModelBody] = useState<string>('');
    const [showLoader, setShowLoader] = useState<boolean>(false)
    const [formData,setFormData] = useState<any>({
        value : '',
        subject : '',
        content : ''
    })
    const [errorContent,setErrorContent] = useState<boolean>(false)

    const onchange = (e) => {
        const {name,value} = e.target
        const re = /^[a-zA-Z ]*$/
    if (value === "" || re.test(value))
        setFormData({...formData,[name] : value.toUpperCase()})
    }

    const changeContent = (value) => {
        setFormData({...formData,content : value})
        if(value){
            setErrorContent(false)
        }
    }

    const submitForm = async(e) => {
        e.preventDefault()
        try{
            if(!formData?.content || formData?.content === '<p><br></p>'){
                setErrorContent(true)
                return null
            }
            setShowLoader(true)
            const resp = await addEmailTemplate(formData)
            setFormData({})
            setModelBody(resp?.data);
            setShow(true)
            setShowLoader(false)
            navigate('/apps/email/list')
        }catch(error){
            console.log(error)
            setShowLoader(false)
        }
    }

    const handleClose = () => {
        setShow(false)
    }

    return(
        <form className="d-flex flex-column" onSubmit={submitForm}>
            <div className="col-lg-6 mb-3">
                <div className="d-flex flex-column">
                <label className="form-label mb-3" htmlFor="value">
                   Email Template Type<span className="required"></span>
                </label>
                <input
                    type="text"
                    value = {formData?.value}
                    required
                    id="value"
                    className="form-control form-control-lg form-control-solid"
                    name="value"
                    onChange={onchange}
                />
                </div>
            </div>
            <div className="col-lg-6 mb-3">
                <div className="d-flex flex-column">
                <label className="form-label mb-3" htmlFor="subject">
                    Subject<span className="required"></span>
                </label>
                <input
                    type="text"
                    value = {formData?.subject}
                    required
                    id="subject"
                    className="form-control form-control-lg form-control-solid"
                    name="subject"
                    onChange={(e)=>{setFormData({...formData,[e.target.name] : e.target.value})}}
                />
                </div>
            </div>
            <style>{"\
                    .jodit-status-bar{\
                        display:none;\
                    }\
                    "}</style>
            <div className="col-lg-12 mb-5">
                <div className="d-flex flex-column">
                <label className="form-label mb-3" htmlFor="content">
                   Email body<span className="required"></span>
                </label>
                {errorContent && <span style={{color : 'red'}}>Email body is required</span>}
                <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-9 p-6">
                    <i className="ki-duotone ki-information fs-2tx text-warning me-4">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                    </i>
                    <div className="d-flex flex-stack flex-grow-1">
                        <div className="fw-semibold">
                            <div className="fs-6 text-gray-700">
                            <strong className="me-1">Warning!</strong>{`Dynamically added text format should be within "[ ]" and in "Camel case", Example : [userName]`}</div>
                        </div>
                    </div>
                </div>
                <JoditEditor
                    ref={editor}
                    value={formData?.content}
                    onChange={newContent => changeContent(newContent)}
                />
                </div>
            </div>
            <div className="align-self-end">
                <button
                    type="submit"
                    className="btn btn-primary mt-5"
                    >
                    Submit
                </button>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Email Template</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modelBody}</Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>
                    Ok
                </Button>
                </Modal.Footer>
            </Modal>
            {showLoader && <PageLoader />}
        </form>
    )
}
export default AddEmailTamplate;