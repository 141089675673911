import { useEffect, useState } from "react";
import { KTIcon } from "../../../../../_metronic/helpers";
import { updateLocationStatus, getLocationByStatus, getCounLocationsByStatus } from "../../services/api";
import { useNavigate } from "react-router-dom";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { LocationsListFilter } from "../LocationsListFilter";
import { Box, CircularProgress } from "@mui/material";
import { LocationDataTable } from "../LocationDataTable";



const ReviewLocations = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<any>([]);
    const [locationCount, setLocationCount] = useState<any>(0)
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [locationType, setLocationType] = useState<string | undefined>()

    const getLocationData = async () => {
        try{
            const countResp = await getCounLocationsByStatus({ status: "Verified", locationType: locationType });
            setLocationCount(Math.ceil(countResp?.data?.count / 50)) //items per page is 50 So, dividing with 50 to make no of pages appear
            const resp = await getLocationByStatus({ page: 1, items: 50, status: "Verified", searchTerm: searchTerm.toLocaleUpperCase(), locationType: locationType });
            const locData = resp?.data?.data;
            setData(locData);
            setIsLoading(false)
        }catch(error){
            console.log(error)
        }
    }
    useEffect(() => {
        getLocationData();
    }, []);

    const handleChange = async (event, value) => {
        try{
            const resp = await getLocationByStatus({ page: value, items: 50, status: "Verified", searchTerm: searchTerm.toLocaleUpperCase(), locationType: locationType });
            setData(resp?.data?.data);
        }catch(error){
            console.log(error)
        }
    };

    const editLocation = (location_id) => {
        navigate(`../location/${location_id}`);
    }

    const updateLocation = async (location_id, status) => {
        try{
            await updateLocationStatus(location_id, status);
            alert("Location : " + location_id + " " + status + " sucessfully")
            getLocationData();
        }catch(error){
            console.log(error)
        }
    }

    useEffect(() => {
        getLocationData();
    }, [searchTerm, locationType])

    const filterData = async (locationType: string, date: string) => {
        setLocationType(locationType);
    }

    const getView = () => {
        if (isLoading) {
          return (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="85vh"
            >
              <CircularProgress size={50} />
            </Box>
          );
        }
    
        return (
          <>
            <LocationDataTable
                    tableName="Approved"
                    data={data} 
                    totalCount={locationCount}
                    editLocation={editLocation} 
                    deactivateLocation={undefined}
                    verifyLocation={undefined}
                    updateLocation={updateLocation}
                    activateLocation={undefined}
                    addTags={undefined}
                />
          </>
        );
      };

    return (
        <div className="card">
            <div className="card-header border-0 pt-6">
                <div className='card-title'>
                    {/* begin::Search */}
                    <div className='d-flex align-items-center position-relative my-1'>
                        <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                        <input
                            type='text'
                            data-kt-user-table-filter='search'
                            className='form-control form-control-solid w-250px ps-14'
                            placeholder='Search user'
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    {/* end::Search */}
                </div>
                <div className="card-toolbar">
                    <LocationsListFilter filterFormData={filterData} />
                </div>
            </div>
            <div className={`card mb-5 mb-xl-8`}>
                {/* begin::Body */}
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                        {/* begin::Table */}
                        {getView()}
                        {/* end::Table */}
                    </div>
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row-reverse', marginRight: "12px" }}>
                <Stack spacing={2}>
                    <Pagination count={locationCount} variant="outlined" shape="rounded" color="primary" onChange={handleChange} />
                </Stack>
            </div>
        </div>

    );
};

export default ReviewLocations;