/* eslint-disable jsx-a11y/anchor-is-valid */
import {KTIcon} from '../../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { getJobDetails} from "../services/api";
import { PageLoader } from "../services/PageLoader";
import {Modal} from 'react-bootstrap'
import Moment from 'moment';

const ViewJob = () => {
  const params = useParams()
  const jobId = params.id
  const [data, setData] = useState<any>([]);
  const [show,setShow] = useState<any>(false)
  const [modelData,setModelData] = useState<any>({})
  const [tile,setTile] = useState<any>('overview')
  const [showLoader, setShowLoader] = useState(false)
    const getJobData = async () => {
      try{
        const res = await getJobDetails(jobId);
        setData(res.data)
        setShowLoader(false)
      }catch(error){
        console.log(error)
        setShowLoader(false)
      }
    }
    useEffect(() => {
        setShowLoader(true)
        getJobData();
    }, []);

    const onClickProfile = (data) => {
      setShow(true)
      setModelData(data)
    }

    const onChangeTile = (tile) => {
      setTile(tile)
    }

    const overView = () => {
      return(
        <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Job Details</h3>
          </div>

          {/* <Link to='/crafted/account/settings' className='btn btn-primary align-self-center'>
            Edit Profile
          </Link> */}
        </div>

        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Posted Date</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'> {Moment(data?.date).format('DD-MM-YYYY')}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Openings</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{data?.openings}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
            Industry Type
              {/* <i
                className='fas fa-exclamation-circle ms-1 fs-7'
                data-bs-toggle='tooltip'
                title='Phone number must be active'
              ></i> */}
            </label>

            <div className='col-lg-8 d-flex align-items-center'>
              <span className='fw-bolder fs-6 me-2'>{data?.IndustryType}</span>

              {/* <span className='badge badge-success'>Verified</span> */}
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Department</label>

            <div className='col-lg-8'>
              <a href='#' className='fw-bold fs-6 text-dark text-hover-primary'>
                {data?.Department}
              </a>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
            Role Category
              {/* <i
                className='fas fa-exclamation-circle ms-1 fs-7'
                data-bs-toggle='tooltip'
                title='Country of origination'
              ></i> */}
            </label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{data?.roleCategory}</span>
            </div>
          </div>

          {/* <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Communication</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>Email, Phone</span>
            </div>
          </div> */}

          <div className='row mb-10'>
            <label className='col-lg-4 fw-bold text-muted'>Description :</label>

            {/* <div className='col-lg-8'>
              <span className='fw-bold fs-6'>Yes</span>
            </div> */}
          </div>
          <div>
          <ol>
                {data?.description?.map((item,i) => {
                  return(
                    <li className='fw-bolder fs-6 text-dark'>{item}</li>
                  )
                })}
              </ol>
          </div>

          {/* <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
            <KTIcon iconName='information-5' className='fs-2tx text-warning me-4' />
            <div className='d-flex flex-stack flex-grow-1'>
              <div className='fw-bold'>
                <h4 className='text-gray-800 fw-bolder'>We need your attention!</h4>
                <div className='fs-6 text-gray-600'>
                  Your payment was declined. To start using tools, please
                  <Link className='fw-bolder' to='/crafted/account/settings'>
                    {' '}
                    Add Payment Method
                  </Link>
                  .
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      )
    }

    const jobRequets = () => {
      return(
        <div className="card">
            <div className="card-header border-0 pt-6">
                <div className='card-title'>
                    {/* begin::Search */}
                    <h2>Requested Profiles</h2>
                    {/* <div className='d-flex align-items-center position-relative my-1'>
                        <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                        <input
                            type='text'
                            data-kt-user-table-filter='search'
                            className='form-control form-control-solid w-250px ps-14'
                            placeholder='Search Job Title'
                            onChange={hanldeSearch}
                        />
                    </div> */}
                    {/* end::Search */}
                </div>
                <div className="card-toolbar">
                    <Link to = {"../job"}>
                        <button type='button' className='btn btn-primary'>
                            <KTIcon iconName='plus' className='fs-2' />
                            Add New Job
                        </button>
                </Link>
        </div>
            </div>
            
            <div>
                {/* begin::Body */}
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                        {/* begin::Table */}
                        <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                            {/* begin::Table head */}
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    <th className='w-25px'>
                                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                            <input
                                                className='form-check-input'
                                                type='checkbox'
                                                value='1'
                                                data-kt-check='true'
                                                data-kt-check-target='.widget-13-check'
                                            />
                                        </div>
                                    </th>
                                    <th className='min-w-100px'>Name</th>
                                    <th className='min-w-100px'>Email</th>
                                    <th className='min-w-100px'>Mbl.no</th>
                                    <th className='min-w-100px'>Education</th>
                                    <th className='min-w-100px'>Applied Date</th>
                                    <th className='min-w-100px text-end'>Skills</th>
                                    <th className='min-w-100px text-end'>Actions</th>
                                </tr>
                            </thead>
                            {/* end::Table head */}
                            {/* begin::Table body */}
                            <tbody>
                                {data?.applications?.map((item, index) => {
                                    return (
                                        <tr>
                                            <td>
                                                <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                                    <input className='form-check-input widget-13-check' type='checkbox' value='1' />
                                                </div>
                                            </td>
                                            <td>
                                                <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                                    {item.name}
                                                </a>
                                            </td>
                                            <td>
                                                <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                    {item?.email}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                    {item?.phoneNumber}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                    {item?.qualification}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='badge badge-light-warning'>
                                                    {Moment(item?.date).format('DD-MM-YYYY')}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                    {item?.skils}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="text-end">
                                                    {/* <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                        title="View Locations">
                                                        <KTIcon iconName='magnifier' className='fs-3' />
                                                    </div> */}
                                                        <div className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                                                            title="View"
                                                            onClick={() => onClickProfile(item)}>
                                                            View Profile
                                                        </div>
                                                    {/* <Link to ={`job/${item._id}`} >
                                                        <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                            title="Update">
                                                            <KTIcon iconName='setting-3' className='fs-3' />
                                                        </div>
                                                    </Link>
                                                    <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                        // onClick={() =>handleDeleteJob(item)}
                                                        title="Delete">
                                                        <KTIcon iconName='trash' className='fs-3' />
                                                    </div> */}
                                                    
                                                </div>
                                            </td>
                                        </tr>

                                    )
                                })}


                            </tbody>
                            {/* end::Table body */}
                        </table>
                        {/* end::Table */}
                    </div>
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>
            {/* <div style={{ display: 'flex', flexDirection: 'row-reverse', marginRight: "12px" }}>
                <Stack spacing={2}>
                    <Pagination count={dataCount} variant="outlined" shape="rounded" color="primary" onChange={handleChange} />
                </Stack>
            </div> */}
            {showLoader && <PageLoader />}
        </div>
      )
    }

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='me-7 mb-4'>
              {/* <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='Metornic' />
                <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
              </div> */}
            </div>

            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                     {data.jobTitle}
                    </a>
                    {/* <a href='#'>
                      <KTIcon iconName='verify' className='fs-1 text-primary' />
                    </a> */}
                  </div>

                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                    >
                      <KTIcon iconName='profile-circle' className='fs-4 me-1' />
                      {data?.role}
                    </a>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                    >
                      <KTIcon iconName='sms' className='fs-4 me-1' />
                      {data?.company}
                    </a>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                    >
                      <KTIcon iconName='geolocation' className='fs-4 me-1' />
                      {data?.location}
                    </a>
                  </div>
                </div>

                {/* <div className='d-flex my-4'>
                  <a href='#' className='btn btn-sm btn-light me-2' id='kt_user_follow_button'>
                    <KTIcon iconName='check' className='fs-3 d-none' />

                    <span className='indicator-label'>Follow</span>
                    <span className='indicator-progress'>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  </a>
                  <a
                    href='#'
                    className='btn btn-sm btn-primary me-3'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_offer_a_deal'
                  >
                    Hire Me
                  </a>
                  <div className='me-0'>
                    <button
                      className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                    >
                      <i className='bi bi-three-dots fs-3'></i>
                    </button>
                    <Dropdown1 />
                  </div>
                </div> */}
              </div>

              <div className='d-flex flex-wrap flex-stack'>
                <div className='d-flex flex-column flex-grow-1 pe-8'>
                  <div className='d-flex flex-wrap'>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        {/* <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' /> */}
                        <div className='fs-2 fw-bolder'>{data.salary}</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>Salary</div>
                    </div>

                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        {/* <KTIcon iconName='arrow-down' className='fs-3 text-danger me-2' /> */}
                        <div className='fs-2 fw-bolder'>{data?.employmentType}</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>Emplyement Type</div>
                    </div>

                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        {/* <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' /> */}
                        <div className='fs-2 fw-bolder'>{data?.experience}</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>Experience</div>
                    </div>
                  </div>
                </div>

                {/* <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
                  <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                    <span className='fw-bold fs-6 text-gray-400'>Profile Compleation</span>
                    <span className='fw-bolder fs-6'>50%</span>
                  </div>
                  <div className='h-5px mx-3 w-100 bg-light mb-3'>
                    <div
                      className='bg-success rounded h-5px'
                      role='progressbar'
                      style={{width: '50%'}}
                    ></div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              <li className='nav-item nav-link text-active-primary me-6 '>
                <div
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (tile === 'overview' && 'active')
                  }
                  onClick={() => onChangeTile('overview')}
                >
                  Overview
                </div>
              </li>
              <li className='nav-item nav-link text-active-primary me-6 '>
                < div
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (tile === 'jobrequests' && 'active')
                  }
                  onClick={() => onChangeTile('jobrequests')}
                >
                  Job Requests
                </div>
              </li>
              {/* <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === '/crafted/pages/profile/campaigns' && 'active')
                  }
                  to='/crafted/pages/profile/campaigns'
                >
                  Campaigns
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === '/crafted/pages/profile/documents' && 'active')
                  }
                  to='/crafted/pages/profile/documents'
                >
                  Documents
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === '/crafted/pages/profile/connections' && 'active')
                  }
                  to='/crafted/pages/profile/connections'
                >
                  Connections
                </Link>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
      {tile === 'overview' && overView()}
      {tile === 'jobrequests' && jobRequets()}
      <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={show}
      backdrop={true}
    >
      <div className='modal-header'>
        <h2>Profile details</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={() => setShow(false)}>
          <KTIcon className='fs-1' iconName='cross' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10' >
          <div>
            <div className='row mb-3'>
              <label className='col-lg-6 fw-bold text-muted'>Name</label>
              <div className='col-lg-6 fv-row'>
                <span className='fw-bold fs-6'>{modelData?.name}</span>
              </div>
            </div>
            <div className='row mb-3'>
              <label className='col-lg-6 fw-bold text-muted'>Email</label>
              <div className='col-lg-6 fv-row'>
                <span className='fw-bold fs-6'>{modelData?.email}</span>
              </div>
            </div>
            <div className='row mb-3'>
              <label className='col-lg-6 fw-bold text-muted'>Phone Number</label>
              <div className='col-lg-6 fv-row'>
                <span className='fw-bold fs-6'>{modelData?.phoneNumber}</span>
              </div>
            </div>
            <div className='row mb-3'>
              <label className='col-lg-6 fw-bold text-muted'>Alt Phone Number</label>
              <div className='col-lg-6 fv-row'>
                <span className='fw-bold fs-6'>{modelData?.altNumber}</span>
              </div>
            </div>
            <div className='row mb-3'>
              <label className='col-lg-6 fw-bold text-muted'>Education</label>
              <div className='col-lg-6 fv-row'>
                <span className='fw-bold fs-6'>{modelData?.qualification}</span>
              </div>
            </div>
            <div className='row mb-3'>
              <label className='col-lg-6 fw-bold text-muted'>Skills</label>
              <div className='col-lg-6 fv-row'>
                <span className='fw-bold fs-6'>{modelData?.skils}</span>
              </div>
            </div>
            <div className='row mb-3'>
              <label className='col-lg-6 fw-bold text-muted'>Experience</label>
              <div className='col-lg-6 fv-row'>
                <span className='fw-bold fs-6'>{modelData?.experience}</span>
              </div>
            </div>
            <div className='row mb-3'>
              <label className='col-lg-6 fw-bold text-muted'>Relevent Exp</label>
              <div className='col-lg-6 fv-row'>
                <span className='fw-bold fs-6'>{modelData?.relExperience}</span>
              </div>
            </div>
            <div className='row mb-3'>
              <label className='col-lg-6 fw-bold text-muted'>Current Ctc</label>
              <div className='col-lg-6 fv-row'>
                <span className='fw-bold fs-6'>{modelData?.currentCtc}</span>
              </div>
            </div>
            <div className='row mb-3'>
              <label className='col-lg-6 fw-bold text-muted'>Expected Ctc</label>
              <div className='col-lg-6 fv-row'>
                <span className='fw-bold fs-6'>{modelData?.expCtc}</span>
              </div>
            </div>
            <div className='row mb-3'>
              <label className='col-lg-6 fw-bold text-muted'>Summary</label>
              <div className='col-lg-6 fv-row'>
                <span className='fw-bold fs-6'>{modelData?.summary}</span>
              </div>
            </div>
            <div className='row mb-3'>
              <label className='col-lg-6 fw-bold text-muted'>Resume</label>
              {modelData?.resume?.url && (
              <div className='col-lg-6 fv-row'>
                <a href={modelData?.resume?.url} target="_blank" className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                    title="View Resume">
                    View Resume
                </a>
              </div>)}
            </div>
          </div>
      </div>
    </Modal>
    </>
  );
};

export default ViewJob;
