import { useEffect, useState } from "react";
import {Modal,Button} from 'react-bootstrap'
import { PageLoader } from "../services/PageLoader";
import { getEnquiryData } from "../services/api";
import { useAuthStore } from "../store/auth";
import Moment from 'moment';

import {KTIcon} from "../../../../_metronic/helpers"

const Enquiry = () => {
    const { userProfile } = useAuthStore();
    const [show,setShow] = useState<any>(false);
    const [modelBody,setModelBody] = useState<string>('');
    const [showLoader, setShowLoader] = useState<boolean>(false);

    // Me
    const data = ["Subscription", "FindMySpot", "EventPlanning"]
    const [enquiryType,setEnquiryType] = useState<string>('Subscription')
    const [formData, setFormData] = useState<any>();
    const [searchTerm, setSearchTerm] = useState<any>();

    const onChangeType = (type : string) => {
        setEnquiryType(type)
    }

    const getData = async () => {
        try{
            const resp = await getEnquiryData(enquiryType)
            setFormData(resp?.data)
            setShowLoader(false)
        }catch(error){
            console.log(error)
            setShowLoader(false)
        }
    }

    const handleSearch = async (e : any) => {
      setSearchTerm(e.target.value)
    //   setShowLoader(true)
    //   try{
    //     const res = await getLocationReviews(locationId,1,noOfItems,e.target.value);
    //     setData(res?.data?.filteredData)
    //     setPageCount(res?.data?.count)
    //     setShowLoader(false)
    //   }catch(error){
    //     console.log(error)
    //     setShowLoader(false)
    //   }
    }

    useEffect(()=> {
        setShowLoader(true)
        getData();
    },[enquiryType]);


    const Subscription = () => {
        return(
            <div className="d-flex flex-column">
            {
                formData.map((each : any) => {

                    return(
                    <div className='card mb-5 mb-xl-10' >
                        <div className='card-header cursor-pointer'>
                            <div className='mb-3'>
                                {/* begin::Text */}
                                <div className='mt-3'>
                                <span  className='fw-bold'>Applicant Name :</span> <span>{each?.data?.name}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Applicant Email :</span> <span>{each?.data?.email}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Applicant Number :</span> <span>{each?.data?.phoneNumber}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Requested Plan :</span> <span>{each?.data?.plan}</span>
                                </div>
                                <div className=' mt-3'>
                                <span  className='fw-bold'>Requested Date :</span> <span>{Moment(each.createdAt).format('DD/MM/YYYY hh:mm a')}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Applicant Message :</span> <span>{each?.data?.message ? each?.data?.message : "N/A"}</span>
                                </div>
                                {/* end::Text */}
                            </div>
                        </div>
                    </div>
                    )
                })
            }
            </div>
        )
    }

    const FindMySpot = () => {
        return(
            <div className="d-flex flex-column">
            {
                formData.map((each : any) => {

                    return(
                    <div className='card mb-5 mb-xl-10' >
                        <div className='card-header cursor-pointer'>
                            <div className='mb-3'>
                                {/* begin::Text */}
                                <div className='mt-3'>
                                <span  className='fw-bold'>Applicant Name :</span> <span>{each?.data?.fullName}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Applicant Email :</span> <span>{each?.data?.email}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Applicant Number :</span> <span>{each?.data?.number}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Event Type :</span> <span>{each?.data?.eventType}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Event Date :</span> <span>{each?.data?.eventDate ? Moment(each?.data?.eventDate).format('DD/MM/YYYY') : "N/A"}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Required Location Type :</span> <span>{each?.data?.locationType ? each?.data?.locationType : "N/A"}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Required Duration :</span> <span>{each?.data?.duration ? each?.data?.duration : "N/A"}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Applicant Requests :</span> <span>{each?.data?.requests ? each?.data?.requests : "N/A"}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Applicant Requirements :</span> <span>{each?.data?.requirements ? each?.data?.requirements : "N/A"}</span>
                                </div>
                                <div className=' mt-3'>
                                <span  className='fw-bold'>Requested Date :</span> <span>{Moment(each.createdAt).format('DD/MM/YYYY hh:mm a')}</span>
                                </div>
                                {/* end::Text */}
                            </div>
                        </div>
                    </div>
                    )
                })
            }
            </div>
        )
    }

    const EventPlanning = () => {
        return(
            <div className="d-flex flex-column">
            {
                formData.map((each : any) => {
                    let reqServices : any
                    if (each?.data?.services){
                    const filteredServices = each?.data?.services?.filter((each : any) => each !== "")
                    reqServices = filteredServices.length > 0 ? each?.data?.services.join(", ") : "N/A";
                    }
                    else {
                        reqServices = "N/A"
                    }

                    return(
                    <div className='card mb-5 mb-xl-10' >
                        <div className='card-header cursor-pointer'>
                            <div className='mb-3'>
                                {/* begin::Text */}
                                <div className='mt-3'>
                                <span  className='fw-bold'>Applicant Name :</span> <span>{each?.data?.fullName}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Applicant Email :</span> <span>{each?.data?.email}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Applicant Number :</span> <span>{each?.data?.number}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Event Type :</span> <span>{each?.data?.eventType ? each?.data?.eventType : "N/A"}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Preferred Event Date :</span> <span>{each?.data?.eventDate ? Moment(each?.data?.eventDate).format('DD/MM/YYYY') : "N/A"}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Preferred Event Location :</span> <span>{each?.data?.eventLocation ? each?.data?.eventLocation : "N/A"}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Estimated Guests :</span> <span>{each?.data?.guests ? each?.data?.guests : "N/A"}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Requested Services :</span> <span>{reqServices}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Applicant Preferences/Requirements :</span> <span>{each?.data?.requirements ? each?.data?.requirements : "N/A"}</span>
                                </div>
                                <div className=' mt-3'>
                                <span  className='fw-bold'>Requested Date :</span> <span>{Moment(each.createdAt).format('DD/MM/YYYY hh:mm a')}</span>
                                </div>
                                {/* end::Text */}
                            </div>
                        </div>
                    </div>
                    )
                })
            }
            </div>
        )
    }

    const ReqEnquiry = () => {
            switch (enquiryType) {
                case "Subscription":
                    return Subscription()
                case "FindMySpot":
                    return FindMySpot()
                case "EventPlanning":
                    return EventPlanning()
                default:
                    break;
            }
    }

    return (
        <>
        <div className='d-flex justify-content-between overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              <li className='nav-item nav-link text-active-primary me-6 cursor-pointer'>
                <div
                  className={`nav-link text-active-primary me-6 ` +
                    (enquiryType === 'Subscription' && 'active' )
                }
                  onClick={() => onChangeType('Subscription')}
                >
                  Subscription
                </div>
              </li>
              <li className='nav-item nav-link text-active-primary me-6 cursor-pointer'>
                <div
                  className={`nav-link text-active-primary me-6 ` +
                    (enquiryType === "FindMySpot" && 'active' )
                }
                  onClick={() => onChangeType('FindMySpot')}
                >
                  Find My Spot
                </div>
              </li>
              <li className='nav-item nav-link text-active-primary me-6 cursor-pointer'>
                <div
                  className={`nav-link text-active-primary me-6 `+
                    (enquiryType === "EventPlanning" && 'active' )
                }
                  onClick={() => onChangeType('EventPlanning')}
                >
                  Event Planning
                </div>
              </li>
            </ul>
            <div className='card-title'>
              {/* begin::Search */}
              {/* <div className='d-flex align-items-center position-relative my-1'>
                  <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                  <input
                      type='text'
                      data-kt-user-table-filter='search'
                      className='form-control form-control-solid w-250px ps-14'
                      placeholder='Search'
                      value={searchTerm}
                      onChange={handleSearch}
                  />
              </div> */}
              {/* end::Search */}
          </div>
          </div>
         {
            formData && ReqEnquiry()
         }
</>
    );
};

export default Enquiry;
