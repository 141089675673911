import { DataGrid } from "@mui/x-data-grid";
import "./dataTable.scss";
import Moment from 'moment';
import { KTIcon } from "../../../../_metronic/helpers";

export const LocationDataTable = ({ tableName, data, totalCount, editLocation, deactivateLocation, verifyLocation, updateLocation, activateLocation,addTags}) => {

  const columns = [
    {
      field: "location_id",
      headerName: "Location ID",
      width: 200,
      renderHeader: () => (
        <th className='min-w-150px'>Location ID</th>
      ),
      renderCell: (record) => (
        <div className='text-dark fw-bold text-hover-primary fs-6'>
          {record.row.location_id}
        </div>
      ),
    },
    {
      field: "location_type",
      headerName: "Location Type",
      valueGetter: (params) => params.row.property_desc.location_type,
      width: 250,
      renderHeader: () => (
        <th className='min-w-150px'>Location Type</th>
      ),
      renderCell: (record) => (
        <div className='text-dark fw-bold text-hover-primary fs-6'>
          {record.row?.property_desc?.location_type}
        </div>
      ),
    },
    {
      field: "property_desc?.user_id",
      valueGetter: (params) => params.row.property_desc.user_id,
      headerName: "User",
      width: 200,
      renderHeader: () => (
        <th className='min-w-150px'>User</th>
      ),
      renderCell: (record) => (
        <div className='text-dark fw-bold text-hover-primary fs-6'>
          {record.row.property_desc?.user_id}
        </div>
      ),
    },
    {
      field: "createdAt",
      headerName: "Crated Date",
      width: 100,
      renderHeader: () => (
        <th className='min-w-150px'>Date</th>
      ),
      renderCell: (record) => (
        <div className='text-dark fw-bold text-hover-primary fs-6'>
          {Moment(record.row.createdAt).format('DD-MM-YYYY')}
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderHeader: () => (
        <th className='min-w-150px'>Status</th>
      ),
      renderCell: (record) => (
        <div className='text-dark fw-bold text-hover-primary fs-6'>
          {tableName === "Approved" && (
            <span className='badge badge-light-success'>{record.row?.status}</span>
          )}
          {tableName === "Incomplete" && (
            <span className='badge badge-light-warning'>Incomplete</span>
          )}
          {tableName === "locationRequests" && (
            <span className='badge badge-light-warning'>{record.row?.status ? record.row?.status : record.row?.verified}</span>
          )}
          {tableName === "locationForApproval" && (
            <span className='badge badge-light-warning'>{record.row?.status}</span>
          )}
          {tableName === "Rejected" && (
            <span className='badge badge-light-failure'>{record.row?.status ? record.row?.status : record.row?.verified}</span>
          )}
        </div>
      ),
    },
    {
      headerName: "Actions",
      width: 200,
      align: 'right',
      renderHeader: () => (
        <th className='min-w-150px'>Actions</th>
      ),
      renderCell: (record) => (
        <div className="d-flex text-end">
          {addTags && (
          <select
          className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2"
          onChange={(e)=>{addTags(e.target.value,record?.row?.location_id)}}
          style={{
            fontSize : '14px'
          }}
          >
            <option className="menu-item px-3">Add to</option>
            <option className="menu-item px-3" value={'featured'} title="Add to feature">Featured</option>
            <option className="menu-item px-3" value={'toprated'} title="Add to feature">Top Rated</option>
            {/* <option value={'Add to'} className="dropdown-item" title="Add to feature" onClick={() => addTags('exclusive',record?.row?.location_id)}>Exclusive</option> */}
          </select>
          )}
          {editLocation && (
            <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() => editLocation(record?.row?.location_id)}
              title="Edit Location">
              <KTIcon iconName='pencil' className='fs-3' />
            </div>
          )}
          {deactivateLocation && (
            <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() => deactivateLocation(record?.row?.location_id)}
              title="Deactivate Location">
              <KTIcon iconName='trash' className='fs-3' />
            </div>
          )}
          {verifyLocation && (
            <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() => verifyLocation(record.row?.location_id)}
              title="Verify Location">
              <KTIcon iconName='verify' className='fs-3' />
            </div>
          )}
          {updateLocation && (
            <>
              <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                  onClick={() => updateLocation(record.row?.location_id, 'Approved')}
                  title="Approve Location">
                  <KTIcon iconName='verify' className='fs-3' />
              </div>
              <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                  onClick={() => updateLocation(record.row?.location_id, 'Rejected')}
                  title="Reject Location">
                  <KTIcon iconName='cross-circle' className='fs-3' />
              </div>
            </>
          )}

          {activateLocation && (
            <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                onClick={() =>activateLocation(record.row?.location_id)}
                title="Activate Location">
                <KTIcon iconName='verify' className='fs-3' />
            </div>
          )}


        </div>
      ),
    },
  ];

  return (
    <div className="datatable">
      <DataGrid
        className="datagrid"
        rows={data}
        columns ={columns}
        pageSize={50}
        rowCount={totalCount}
        //rowsPerPageOptions={[9]}
        getRowId={(row) => row.location_id}
        //paginationMode="server"
        sx={{
          "& .MuiDataGrid-row:hover": {
            backgroundColor: "inherit"
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "1px solid var(--bs-gray-100)" 
          },
          "& .MuiDataGrid-footerContainer": {
            display: "none"
          }
        }}
      />
    </div>
  );
};
