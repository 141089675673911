/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
  EngageWidget10,
} from '../../../_metronic/partials/widgets'
import { getCounLocationsByStatus, noOfUsers, noOfBookings, } from '../../modules/apps/services/api'
import { useAuthStore } from "../../modules/apps/store/auth";

const DashboardPage: FC = () => {
  

  const [approvedLocationCount, setApprovedLocationCount] = useState<number>(0)
  const [allLocationCount, setAllLocationCount] = useState<number>(0)
  const [usersCount, setUsersCount] = useState<number>(0)
  const [confirmedBookingsCount, setConfirmedBookingsCount] = useState<number>(0)
  const [bookingsCount, setBookingsCount] = useState<number>(0)
  const { userProfile, setUserProfile } = useAuthStore();

  const getCounts = async () => {
    const allCountResp = await getCounLocationsByStatus({status : "Approved"})
    setApprovedLocationCount(allCountResp?.data?.count);

    const countResp = await getCounLocationsByStatus({status : ""})
    setAllLocationCount(countResp?.data?.count);

    const usersResp = await noOfUsers();
    setUsersCount(usersResp?.data?.count);

    const allCount = await noOfBookings({status : "Confirmed"})
    setConfirmedBookingsCount(allCount?.data?.count);

    const countRes = await noOfBookings({status : ""})
    setBookingsCount(countRes?.data?.count);

  }
  useEffect(() => {
      getCounts();
      setUserProfile();
  }, []);

  return (
  <>
    {/* begin::Row */}
    <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
      {/* begin::Col */}
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
        <CardsWidget20
          className='h-md-50 mb-5 mb-xl-10'
          description='Active Locations'
          color='#CC3333'
          img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          headerCount={approvedLocationCount}
          bodyCount={allLocationCount - approvedLocationCount}
          bodyPercentage={Number(approvedLocationCount / allLocationCount * 100).toFixed(2)}
        />
        <CardsWidget7
          className='h-md-50 mb-5 mb-xl-10'
          description='Users'
          icon={false}
          stats={usersCount}
          labelColor='dark'
          textColor='gray-300'
        />
      </div>
      {/* end::Col */}

      {/* begin::Col */}
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
        <CardsWidget20
            className='h-md-50 mb-5 mb-xl-10'
            description='Active Bookings'
            color='#080655'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
            headerCount={confirmedBookingsCount}
            bodyCount={bookingsCount - confirmedBookingsCount}
            bodyPercentage={(bookingsCount > 0 && confirmedBookingsCount > 0) ? Number(confirmedBookingsCount / bookingsCount * 100).toFixed(2) : 0}
          />
        <CardsWidget17 className='h-md-50 mb-5 mb-xl-10' />
        {/* <ListsWidget26 className='h-lg-50' /> */}
      </div>
      {/* end::Col */}

      {/* begin::Col */}
      {/* <div className='col-xxl-6'>
        <EngageWidget10 className='h-md-100' />
      </div> */}
      {/* end::Col */}
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className='row gx-5 gx-xl-10'>
      {/* begin::Col */}
      <div className='col-xxl-6 mb-5 mb-xl-10'>
        {/* <app-new-charts-widget8 cssclassName="h-xl-100" chartHeight="275px" [chartHeightNumber]="275"></app-new-charts-widget8> */}
      </div>
      {/* end::Col */}

      {/* begin::Col */}
      <div className='col-xxl-6 mb-5 mb-xl-10'>
        {/* <app-cards-widget18 cssclassName="h-xl-100" image="./assets/media/stock/600x600/img-65.jpg"></app-cards-widget18> */}
      </div>
      {/* end::Col */}
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    {/* <div className='row gy-5 gx-xl-8'>
      <div className='col-xxl-4'>
        <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
      </div>
      <div className='col-xl-8'>
        <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
      </div>
    </div> */}
    {/* end::Row */}

    {/* begin::Row */}
    {/* <div className='row gy-5 g-xl-8'>
      <div className='col-xl-4'>
        <ListsWidget2 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ListsWidget6 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />
      </div>
    </div> */}
    {/* end::Row */}

    {/* <div className='row g-5 gx-xxl-8'>
      <div className='col-xxl-4'>
        <MixedWidget8
          className='card-xxl-stretch mb-xl-3'
          chartColor='success'
          chartHeight='150px'
        />
      </div>
      <div className='col-xxl-8'>
        <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' />
      </div>
    </div> */}
  </>
)
  }
const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
