import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import DeactivatedLocations from './deactivated/DeactivatedLocations'
import ReviewLocations from './reviewed/ReviewLocations'
import { EditLocation } from './edit/EditLocation'
import LocationRequests from './requests/LocationRequests'
import IncompleteLocations from './incomplete/IncompleteLocations'
import ApprovedLocations from './approved/ApprovedLocations'
import RejectedLocations from './rejected/RejectedLocations'
import FeaturedList from './featured/featuredList'
import TopRatedList from './topRated/topRatedList'

const locationsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Location Management',
    path: '/apps/location-management/incomplete',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const LocationManagementPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='incomplete'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>Incomplete locations</PageTitle>
              <IncompleteLocations/>
            </>
          }
        />
        {/* <Route
          path='location/:locationId'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>Incomplete locations</PageTitle>
              <EditLocation />
            </>
          }
        /> */}
      </Route>
      <Route element={<Outlet />}>
        <Route
          path='requests'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>Location Requests</PageTitle>
              <LocationRequests />
            </>
          }
        />
      </Route>
      <Route element={<Outlet />}>
        <Route
          path='reviewed'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>Locations for approval</PageTitle>
              <ReviewLocations />
            </>
          }
        />
      </Route>
      <Route element={<Outlet />}>
        <Route
          path='approved'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>Approved locations</PageTitle>
              <ApprovedLocations/>
            </>
          }
        />
      </Route>
      <Route element={<Outlet />}>
        <Route
          path='rejected'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>Rejected locations</PageTitle>
              <RejectedLocations />
            </>
          }
        />
      </Route>
      <Route element={<Outlet />}>
        <Route
          path='deactivated'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>Deactivated locations</PageTitle>
              <DeactivatedLocations />
            </>
          }
        />
      </Route>
      <Route element={<Outlet />}>
        <Route
          path='featured'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>Featured locations</PageTitle>
              <FeaturedList />
            </>
          }
        />
      </Route>
      <Route element={<Outlet />}>
        <Route
          path='toprated'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>Top Rated locations</PageTitle>
              <TopRatedList />
            </>
          }
        />
      </Route>
      {/* <Route element={<Outlet />}>
        <Route
          path='edit'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>Edit location</PageTitle>
              <EditLocation />
            </>
          }
        />
      </Route> */}
      <Route
          path='location/:locationId'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>Edit location</PageTitle>
              <EditLocation isTempLocation={false}/>
            </>
          }
        />
        <Route
          path='incompletelocation/:locationId'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>Edit Incomplete location</PageTitle>
              <EditLocation isTempLocation={true}/>
            </>
          }
        />
      <Route index element={<Navigate to='/apps/location-management/incomplete' />} />
    </Routes>
  )
}

export default LocationManagementPage
