import { useEffect, useState } from "react";
import {Link} from 'react-router-dom'
import {getAllEmailTemplates, deleteEmailTemplate} from "../../services/api";
import {Modal,Button} from 'react-bootstrap'
import { PageLoader } from "../../services/PageLoader";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { useAuthStore } from "../../store/auth";

const EmailTemplateList = () => {
    const { userProfile } = useAuthStore();
    const [testimonialsData,setTestimonialsData] = useState<Array<Object>>([]);
    const [dataCount, setDataCount] = useState<any>(0)
    const [searchTerm,setSearchTerm] = useState<string>('');
    const [show,setShow] = useState<any>(false);
    const [modelBody,setModelBody] = useState<string>('');
    const [showLoader, setShowLoader] = useState<boolean>(false)
    const [page, setPage] = useState<number>(1)
    const noOfItems : number = 9

    const getData = async () => {
        try{
            const response = await getAllEmailTemplates({page,noOfItems});
            setTestimonialsData(response?.data?.templatesData);
            setDataCount(response?.data?.count)
            setShowLoader(false)
        }catch(error){
            console.log(error)
            setShowLoader(false)
        }
    }

    const handleDelete = async(id) => {
        try{
            setShowLoader(true)
            const resp : any = await deleteEmailTemplate(id);
            setModelBody(resp?.data);
            setShow(true)
            setShowLoader(false)
            getData()
        }catch(error){
            console.log(error)
            setShowLoader(false)
        }
    }

    //search
    // const onSearch = async(e) => {
    //     setSearchTerm(e.target.value);
    //     try{
    //         // setShowLoader(true);
    //         const response = await getEmployees(e.target.value);
    //         setEmployeeData(response?.data)
    //         // setShowLoader(false)

    //     }catch(error){
    //         console.log(error)
    //         // setShowLoader(false)
    //     }
    // }
    useEffect(()=>{
        setShowLoader(true)
        getData();
    },[]);

    useEffect(()=>{
        setShowLoader(true)
        getData();
    },[page])


    return(
        <div id="kt_app_content" className="app-content flex-column-fluid">
            {userProfile?.permissions?.Email_Management?.write && (<div className="d-flex justify-content-end mb-3" data-kt-user-table-toolbar="base">
            <Link to ={`../add`} ><button type="button" className="btn btn-primary">
                <i className="ki-duotone ki-plus fs-2"></i>Create Tamplate</button></Link>
            </div>)}
            {/*<!--begin::Content container-->*/}
            <div className='row g-5 g-xl-8'>
                {testimonialsData?.map((item: any) => {
                    return(
                        <div className='col-xl-4'>
                            <div
                                className='card bgi-no-repeat card-xl-stretch mb-5 mb-xl-8'
                                style={{
                                    backgroundPosition: 'right top',
                                    backgroundSize: '30% auto',
                                }}
                                >
                                {/* begin::Body */}
                                <div className='card-body d-flex flex-column justify-content-between align-items-start'>
                                    <p className='card-title fw-bold text-muted text-hover-primary fs-4'>
                                    {item?.value}
                                    </p>
                                    <div>
                                    <span className='text-dark-75 fw-semibold fs-5 m-0'>Subject : </span>
                                    <p className='fw-bold text-primary'>{item?.subject}</p>
                                    </div>
                                    <div>
                                    <span className='text-dark-75 fw-semibold fs-5 m-0'>Email body : </span>
                                    <p
                                    
                                    dangerouslySetInnerHTML={{__html: item?.content.substring(0,400)}}
                                    ></p>
                                    </div>
                                    <div className="">
                                    {userProfile?.permissions?.Email_Management?.write && (<Link to={`../edit/${item._id}`}><button type="button" className="btn btn-light btn-active-light-primary my-1" >Edit</button></Link>)}
                                    {userProfile?.permissions?.Email_Management?.delete && (<button type="button" className="btn btn-light btn-active-primary my-1 m-2" onClick={() => handleDelete(item?._id)}>Delete</button>)}
                                    </div>
                                </div>
                                {/* end::Body */}
                                </div>
                        </div>
                    )
                })}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row-reverse', marginRight: "12px" }}>
                <Stack spacing={2}>
                    <Pagination count={dataCount} variant="outlined" shape="rounded" color="primary" onChange={(e,value)=>setPage(value)} />
                </Stack>
            </div>
            <Modal show={show} onHide={()=>setShow(false)}>
                <Modal.Header closeButton>
                <Modal.Title>Email Template</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modelBody}</Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={()=>setShow(false)}>
                    Ok
                </Button>
                </Modal.Footer>
            </Modal>
            {showLoader && <PageLoader />}
            {/*<!--end::Content container-->*/}
        </div>
    )
}
export default EmailTemplateList;