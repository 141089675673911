import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { KTIcon } from "../../../../../_metronic/helpers";

const UsersList = () => {

	const [searchTerm, setSearchTerm] = useState('')

	const handleSearch = (e) => {
		setSearchTerm(e.target.value)
		const filteredData = filterData?.filter((each) => {
			if ((each.email.toLowerCase().includes((e.target.value).toLowerCase())) ||
				(each.username.toLowerCase().includes((e.target.value).toLowerCase())) ||
				(each?.id.toLowerCase()).includes((e.target.value).toLowerCase())) {
				return each
			}
		})
		setData(filteredData)
	}

	const userColumns = [
		{
			field: "id", headerName: "ID", width: 250,
			renderHeader: () => (
				<th className='min-w-150px'>ID</th>
			),
			renderCell: (record) => (
				<div className='text-dark fw-bold text-hover-primary fs-6'>
					{record.row.id}
				</div>
			),
		},
		{
			field: "username",
			headerName: "User",
			width: 230,
			renderHeader: () => (
				<th className='min-w-150px'>User</th>
			),
			renderCell: (params) => {
				return (
					<div className='text-dark fw-bold text-hover-primary fs-6'>
						{/* <img className="cellImg" src={params.row.img} alt="" /> */}
						{params.row.username}
					</div>
				);
			},
		},
		{
			field: "email",
			headerName: "Email",
			width: 230,
			renderHeader: () => (
				<th className='min-w-150px'>Email</th>
			),
			renderCell: (record) => (
				<div className='text-dark fw-bold text-hover-primary fs-6'>
					{record.row.email}
				</div>
			),
		},
		{
			field: "mobile",
			headerName: "Phone Number",
			width: 150,
			renderHeader: () => (
				<th className='min-w-150px'>Phone Number</th>
			),
			renderCell: (record) => (
				<div className='text-dark fw-bold text-hover-primary fs-6'>
					{record.row.mobile}
				</div>
			),
		},
		{
			field: "date",
			headerName: "Date Of Creation",
			width: 130,
			renderHeader: () => (
				<th className='min-w-150px'>Date Of Creation</th>
			),
			renderCell: (record) => (
				<div className='text-dark fw-bold text-hover-primary fs-6'>
					{record.row.date}
				</div>
			),
		},
	];
	const [data, setData] = useState([]);
	const [filterData,setFilterData] = useState([]);

	useEffect(() => {
		var data2 = [];
		const auth = localStorage.getItem("admin");
		axios
			.get(`https://devapi.spotlet.in/auth/users`,{
				headers: {
					Authorization: `Bearer ${auth}`,
				},
			})
			.then((response) => {
				const data = response.data;
				for (let i = 0; i < data.length; i++) {

					const date = new Date(data[i].createdAt);
					const yyyy = date.getFullYear();
					let mm = date.getMonth() + 1; // Months start at 0!
					let dd = date.getDate();

					if (dd && dd < 10) dd = "0" + dd;
					if (mm && mm < 10) mm = "0" + mm;

					const formattedToday = dd + "/" + mm + "/" + yyyy

					const user = {
						id: data[i]._id,
						email: data[i]?.personalInfo?.email,
						username: data[i]?.personalInfo?.fullName,
						mobile: data[i]?.personalInfo?.mobile,
						img: data[i]?.personalInfo?.profile_pic,
						JoinedAs: data[i]?.personalInfo?.booking_type,
						Address: "",
						Country: "India",
						BankName: "lorem ipsum 1",
						IFSC_Code: "ABC12345",
						AcNo: "123456",
						UPI: "ABC123",
						UserID: data[i].id,
						bookingInfo: data[i].listedLocations,
						date: formattedToday
					};
					data2 = [...data2, user];
				}
			})
			.then(() => {
				setData(data2);
				setFilterData(data2)
			});
	}, []);

	const handleDelete = (id) => {
		setData(data.filter((item) => item.id !== id));
	};

	const actionColumn = [
		{
			field: "action",
			headerName: "Action",
			width: 50,
			renderHeader: () => (
				<th className='min-w-150px'>Action</th>
			  ),
			renderCell: (params) => {
				return (
					<div className="text-end">
						<div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
							<Link to={`/apps/users/${params.id}`} style={{ textDecoration: "none" }}>
								<KTIcon iconName='magnifier' className='fs-3' />
							</Link>
						</div>
					</div>
					// <div className="cellAction">
					// 	<Link to={`/apps/users/${params.id}`} style={{ textDecoration: "none" }}>
					// 		<div className="viewButton">View</div>
					// 	</Link>
					// </div>
				);
			},
		},
	];

	return (


		<div className="card">
			<div className="card-header border-0 pt-6">
				<div className='card-title'>
					{/* begin::Search */}
					<div className='d-flex align-items-center position-relative my-1'>
						<KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
						<input
							type='text'
							data-kt-user-table-filter='search'
							className='form-control form-control-solid w-250px ps-14'
							placeholder='Search user'
							value={searchTerm}
							onChange={handleSearch}
						/>
					</div>
					{/* end::Search */}
				</div>
				<div className="card-toolbar">
					{/* <LocationsListFilter filterFormData={filterData}/> */}
				</div>
			</div>
			<div>
				{/* begin::Body */}
				<div className='card-body py-3'>
					{/* begin::Table container */}
					<div className='table-responsive'>
						{/* begin::Table */}
						<div className="datatable">
							<DataGrid
								className="datagrid"
								rows={data}
								columns={userColumns.concat(actionColumn)}
								pageSize={10}
								rowsPerPageOptions={[9]}
								sx={{
									"& .MuiDataGrid-row:hover": {
										backgroundColor: "inherit"
									},
									"& .MuiDataGrid-cell": {
										borderBottom: "1px solid var(--bs-gray-100)"
									}

								}}
							/>
						</div>
						{/* end::Table */}
					</div>
					{/* end::Table container */}
				</div>
				{/* begin::Body */}
			</div>
			<div style={{ display: 'flex', flexDirection: 'row-reverse', marginRight: "12px" }}>
				{/* <Stack spacing={2}>
                        <Pagination count={locationCount} variant="outlined" shape="rounded" color="primary" onChange={handleChange}/>
                    </Stack> */}
			</div>
		</div>


	);
};

export default UsersList;
