import { useEffect, useState } from "react";
import { KTIcon } from "../../../../../_metronic/helpers";
import { getBookings, noOfBookings } from "../../services/api";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Moment from 'moment';
import { BookingListFilter } from "../BookingListFilter";
import { Link } from 'react-router-dom'

const BookingsList = () => {

    const [data, setData] = useState<any>([]);
    const [dataCount, setDataCount] = useState<any>(0)
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [bookingStatus, setBookingStatus] = useState<string | undefined>()
    const [paymentStatus, setPaymentStatus] = useState<string | undefined>()


    const getLocationData = async () => {
        try {
            const countResp = await noOfBookings({ status: bookingStatus, paymentStatus: paymentStatus, searchTerm: searchTerm });
            setDataCount(Math.ceil(countResp?.data?.count / 50)) //items per page is 50 So, dividing with 50 to make no of pages appear
            const resp = await getBookings({ page: 1, items: 50, status: bookingStatus, paymentStatus: paymentStatus, searchTerm: searchTerm });
            setData(resp?.data);
        } catch (error) {
            console.log(error)
        }

    }

    useEffect(() => {
        getLocationData();
    }, []);

    useEffect(() => {
        getLocationData();
    }, [searchTerm, bookingStatus, paymentStatus]);

    const handleChange = async (event, value) => {
        try {
            const resp = await getBookings({ page: value, items: 50, status: bookingStatus, paymentStatus: paymentStatus, searchTerm: searchTerm });
            setData(resp?.data);
        } catch (error) {
            console.log(error)
        }
    };

    const filterData = async (bookingStatus: string, paymentStatus: string) => {
        setBookingStatus(bookingStatus);
        setPaymentStatus(paymentStatus);
    }

    return (
        <div className="card">
            <div className="card-header border-0 pt-6">
                <div className='card-title'>
                    {/* begin::Search */}
                    <div className='d-flex align-items-center position-relative my-1'>
                        <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                        <input
                            type='text'
                            data-kt-user-table-filter='search'
                            className='form-control form-control-solid w-250px ps-14'
                            placeholder='Search booking'
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    {/* end::Search */}
                </div>
                <div className="card-toolbar">
                    <BookingListFilter filterFormData={filterData} />
                </div>
            </div>

            <div>
                {/* begin::Body */}
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                        {/* begin::Table */}
                        <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                            {/* begin::Table head */}
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    {/* <th className='w-25px'>
                                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                            <input
                                                className='form-check-input'
                                                type='checkbox'
                                                value='1'
                                                data-kt-check='true'
                                                data-kt-check-target='.widget-13-check'
                                            />
                                        </div>
                                    </th> */}
                                    <th className='min-w-100px'>Booking ID</th>
                                    <th className='min-w-100px'>Location Id</th>
                                    <th className='min-w-100px'>Contact name</th>
                                    <th className='min-w-100px'>Booking Status</th>
                                    <th className='min-w-100px'>Payment Status</th>
                                    <th className='min-w-140px'>Date</th>
                                    <th className='min-w-100px'>Time Duration</th>
                                    <th className='min-w-100px'>Total Amount</th>
                                    {/* <th className='min-w-100px text-end'>Actions</th> */}
                                </tr>
                            </thead>
                            {/* end::Table head */}
                            {/* begin::Table body */}
                            <tbody>
                                {data?.map((item, index) => {
                                    return (
                                        <tr>
                                            {/* <td>
                                                <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                                    <input className='form-check-input widget-13-check' type='checkbox' value='1' />
                                                </div>
                                            </td> */}
                                            <td>
                                                <Link to={`../details/${item.bookingId}`} >
                                                    <a className='text-dark fw-bold text-hover-primary fs-6'>
                                                        {item.bookingId}
                                                    </a>
                                                </Link>
                                            </td>
                                            <td>
                                                <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                    {item?.property_id}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                    {item?.contact_name}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='badge badge-light-warning'>
                                                    {item?.status}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='badge badge-light-warning'>
                                                    {item?.payment_status}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                    {Moment(item.createdAt).format('DD-MM-YYYY')}
                                                </div>
                                            </td>
                                            <td>
                                                <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                    {item?.bookedTimeDates[0]?.no_of_hours} Hrs
                                                </span>
                                            </td>
                                            <td>
                                                <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-end'>{item?.totalPayment}</span>
                                            </td>
                                        </tr>

                                    )
                                })}


                            </tbody>
                            {/* end::Table body */}
                        </table>
                        {/* end::Table */}
                    </div>
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row-reverse', marginRight: "12px" }}>
                <Stack spacing={2}>
                    <Pagination count={dataCount} variant="outlined" shape="rounded" color="primary" onChange={handleChange} />
                </Stack>
            </div>
        </div>

    );
};

export default BookingsList;
